// #region Imports and Types

import React, { useCallback, useState } from 'react'
import debounce from 'lodash.debounce';
import { ImSpinner7 } from "react-icons/im";

import CheckUsername from '../services/checkUsername_service';
import { g } from 'vitest/dist/chunks/suite.B2jumIFP';

enum CREATE_ACCOUNT_ERRORS {
    
    USERNAME_TAKEN = "Username is already taken!",
    USERNAME_TOO_SHORT = "Username must be at least 4 characters long!",
    UNEXPECTED_ERROR = "An unexpected error occurred - please try again later",
    NO_SPECIAL_CHARACTERS_ALLOWED = "Only special characters '-', '_', and '.' are allowed!"
}

// #endregion

export default function ChangeUsername({ goBack, createNewUser } : {goBack: () => void, createNewUser: (username: string) => void}) {

    // #region Variables

    const [username, setUsername] = useState<string>(''); // State variable to store the user's username
    const [canCreate, setCanCreate] = useState<boolean>(null); // State variable to determine if the user can create a username
    const [isProcessing, setIsProcessing] = useState<boolean>(false); // State variable to determine if we're processing a request to the database
    const [pageError, setPageError] = useState<string>(''); // State variable to store any errors that occur on the page

    // #endregion

    // #region Helper Methods

    // Method to return the TailwindCSS color associated with the method parameter "_isValid"
    const getAvailabilityColor = (_isValid: boolean) : string => {
        if(_isValid == null) 
            return 'dull-white';

        if(_isValid) 
            return 'neon-purple';

        return 'error-red';
    }

    const preCheckUsername = (_name: string,
                              setCanCreate: React.Dispatch<React.SetStateAction<boolean>>,
                              setPageError: React.Dispatch<React.SetStateAction<string>>) : boolean => {

        if(_name.length === 0) 
            return false;

        // Check that the username isn't less than 4 characters
        if(_name.length > 0 && _name.length < 4) {
            setCanCreate(false);
            setPageError(CREATE_ACCOUNT_ERRORS.USERNAME_TOO_SHORT);
            return false;;
        }

        // Make sure that the only 'special' characters are '-', '_', and '.'
        if(!(/^[a-zA-Z0-9_.-]*$/.test(_name))) {
            setCanCreate(false);
            setPageError(CREATE_ACCOUNT_ERRORS.NO_SPECIAL_CHARACTERS_ALLOWED);
            return false;;
        }

        return true;
    }

    // Method that fires every time the user types in the username textbox, with a delay of 500ms
    const checkUsername_debounced = debounce(
        async (
            _name: string, 
            setCanCreate: React.Dispatch<React.SetStateAction<boolean>>, 
            setPageError: React.Dispatch<React.SetStateAction<string>>,
            setIsProcessing: React.Dispatch<React.SetStateAction<boolean>>
         ) => {

        // Check if the username is available
        try {
            setIsProcessing(true); // Set the processing state to true

            // Check if the username is available, keeping the db connection open between each call
            const isUsernameAvailable = await CheckUsername(_name, false);

            if(!isUsernameAvailable) {
                setCanCreate(false);
                setPageError(CREATE_ACCOUNT_ERRORS.USERNAME_TAKEN);
                }
            else
            {
                // Username is available!
                setCanCreate(true);
            }
        }
        catch(err) {
            setCanCreate(false);
            setPageError(CREATE_ACCOUNT_ERRORS.UNEXPECTED_ERROR);
            return;
        }
        finally {
            setIsProcessing(false); // Mark the processing as complete
        }

    }, 500);

    /**
     * Custom hook that returns a debounced version of the `checkUsername` function.
     * 
     * This hook uses `useCallback` to memoize the debounced function, ensuring that
     * the same debounced function is returned on every render. The same version of checkUsername_debounced will return
     * on every render, unless the dependencies change.
     * If this wasn't the case, the method's 'cancel' method (used to cancel pending debounced calls) wouldn't work as expected,
     * as they would point to a different instance of the function.
     * 
     * @returns {Function} A debounced version of the `checkUsername` function.
     */
    const useDebouncedCheckUsername = () => {
        return useCallback(checkUsername_debounced, []);
    }

    const debouncedCheckUsername = useDebouncedCheckUsername() as typeof checkUsername_debounced & { cancel: () => void };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        const localName = event.target.value;

        setPageError(''); // Reset the error message
        setCanCreate(null); // Reset the canCreate state
        setUsername(localName); // Update the username

        debouncedCheckUsername.cancel(); // Cancel any pending debounced calls

        // Check the username for proper formatting
        if(!preCheckUsername(localName, setCanCreate, setPageError))
            return;

        // Call the debounced function
        debouncedCheckUsername(localName, setCanCreate, setPageError, setIsProcessing);
    }

    const handleGoBack = async () => {
        console.log("(ChangeUsername.tsx) - Making the call to close the persistent MongoDB connection!");
        await CheckUsername("", true);

        goBack();
    }

    const handleCreateUser = async (_name: string) => {

        console.log("(ChangeUsername.tsx) - Making the call to close the persistent MongoDB connection!");
        await CheckUsername(_name, true);

        // Signal to our parent caller to add the new user to the database
        createNewUser(_name);
    }

    //#endregion

    //#region TailwindCSS Styles

    //${(username.length > 0) ? 'dull-white' : 'transparent'}
    const containerStyle = 'relative flex flex-col w-1/2 mx-auto mt-12 bg-purple-base bg-opacity-10'
    const userNameTextboxStyle =  'w-3/4 h-10 mt-7 text-lg text-center text-dull-white font-liberation-mono bg-transparent border-b-2 border-dull-white ' + 
                                  `outline-none caret-${(username.length > 0) ? 'dull-white' : 'transparent'} focus:border-neon-purple`
    const buttonContainerStyle = 'flex w-1/4 mx-auto mt-4 justify-between'
    const buttonAppearanceStyle = 'w-21 h-10 text-lg text-dull-white font-liberation-mono font-bold bg-transparent ' +
                                  'select-none hover:text-selection-color hover:scale-110 transition-transform duration-150';
    const createButtonAppearanceStyle = buttonAppearanceStyle + ` disabled:text-disabled-grey pointer-events-${canCreate ? "auto" : "none"}`;
    const messageContainerStyle = 'inline-flex w-1/2 items-center justify-center mx-auto mt-3 text-sm font-liberation-mono';

    //#endregion

    // #region JSX Return

    return (

        <div id='change-username-container' className={containerStyle}>
            
            {/* Change Username Title */}
            <div className='text-center text-3xl font-nidus-regular text-neon-purple'>
                Create Username
            </div>

            {/* Page Context */}
            <div className='mt-5 text-center font-liberation-mono text-white'>
                <>
                    Your readers will be able to reach your Universe at
                    <br />
                    mystarpath.net/<span className={`text-${getAvailabilityColor(canCreate)}`}>@{username} </span>
                </>           
            </div>
                
            {/* Username Input */}
            <div id='username-input-container' className='inline-flex w-1/2 mt-1 justify-center mx-auto items-end'>

                <input type='text' 
                       placeholder='Username' 
                       maxLength={15}
                       className={userNameTextboxStyle}
                       onChange={handleInputChange} />

                    {/* Spinner */}
                    <div className='absolute w-1/2 flex items-center'>
                        { isProcessing && <ImSpinner7 size={20} className='ml-auto mr-6 mb-1 animate-spin text-neon-purple' /> }
                    </div>

            </div>

            {/* Message Context */}
            <div id='message-container' className={messageContainerStyle}>

                    { (canCreate === false) && 

                        <div className='absolute items-center text-error-red'> 
                            { pageError }
                        </div>
                    }

                    { (canCreate === true) && 

                        <div className='absolute items-center text-neon-purple'> 
                            Username is available!
                        </div>
                    }

            </div>
            
            {/* Go Back and Create Buttons */}
            <div id='button-container' className={buttonContainerStyle}>

                {/* Go Back Button */}
                <button id='go-back-btn'
                        className={buttonAppearanceStyle}
                        onClick={handleGoBack}>
                            [Go Back]
                </button>

                {/* Create Button */}
                <button id='create-btn'
                        className={createButtonAppearanceStyle}
                        disabled={ (!canCreate || isProcessing) }
                        onClick={() => handleCreateUser(username)}>
                            [Create]
                </button>

            </div>

        </div>
    )

    // #endregion
}