// #region Imports and Types

import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { animated, useSpring } from '@react-spring/web';

import GradientButton from './GradientButton';
import {IoMenuSharp} from 'react-icons/io5';
import { FaUserAlt } from "react-icons/fa";
import { Colors } from '../data types/mystarpath_types';
import useWindowSize, { WINDOW_MODE } from '../library/WindowInterface';
import { UserManagementContext } from '../data_providers/UserManagement';
import UserProfileCard from './UserProfileCard';

// #endregion

// Params:
// onLogoutRequestReceived: () => void
// Callback to handle the logout request in the User's Profile Card, to cast up to the Galaxy Scene */ }
const NavBar = ({ onLogoutRequestReceived } : { onLogoutRequestReceived: () => void }) => {

  console.log("(NavBar) - Rendering!");

  // #region Variables

  const navigate = useNavigate(); // Hook to navigate to different pages
  const UserMananagement = useContext(UserManagementContext);
  const User = UserMananagement.getAuthenticatedUser(); // The currently authenticated user
  const { width, height, mode } = useWindowSize(); // Hook to get the window size

  // #endregion
  
  // #region Animations

  const [expandNavBarTrigger, setExpandNavBarTrigger] = useState<boolean>(false);
  const [isLogoutCollapsed, setIsLogoutCollapsed] = useState<boolean>(false);
  const navBarCollapsedHeight = 40; // Height of the NavBar when collapsed
  const expandNavBarTime = 200; // Time in milliseconds for the NavBar to expand

  const getExpandedHeight = (h: number) => {
    if(h < 450) return h * 0.90;
    if(h < 850) return h * 0.65;
    return h * 0.45;
  }

  const navBarHeightAnimation = useSpring({
    height: expandNavBarTrigger ? getExpandedHeight(height) : navBarCollapsedHeight,
    from: { height: navBarCollapsedHeight  }, // Initial collapsed height of the NavVar
    config: { duration: expandNavBarTime },
});

const toggleNavBar = () => { 

  if((mode == WINDOW_MODE.MOBILE) || (mode == WINDOW_MODE.LANDSCAPE)) {
    // Bring into view the SideBar window!

  }
  else // Trigger animation, for Normal and "Halved" window orientations
  {
    setExpandNavBarTrigger(!expandNavBarTrigger)
  }

}

  // #endregion

  // #region Tailwind Styles 

      /* Tailwind CSS breakpoint (width) sizes:
        sm: 640px,
        md: 768px,
        lg: 1024px,
        xl: 1280px,
        2xl: 1536px
    */

  const navBarStyle = 'absolute w-full ' +  // Absolutely positioned at the top of the page, taking up the full width of the page
    'flex justify-between ' +  // Flex container, with (default) child items arranged left->right; with space between the items
    'mt-1 xl:mt-2 ' +  // Margin top 2 units on small screens, 4 units on medium screens
    'bg-transparent z-20';  // Ensure that the NavBar can receive click events on top of the Canvas

  const borderContainerStyle = 'flex flex-col w-full sm:w-72 ' + // flex container, column-oriented, and taking up 1/4 of the width of the parent container
    'mx-2 ' + // Margin to the left and right of the container
    'border-2 border-purple-base rounded-lg ' + // Rounded corners, with purple border
    'bg-purple-base bg-opacity-10 ' +  // Background color of the container
    'overflow-hidden'; // Contained items are hidden, unless this container is large enough to display them

  const navItemContainerStyle = 'flex flex-col ' + // Flex container, with child items arranged top->bottom
    'w-full h-full ' + // Taking up the full width and height of the parent container
    'bg-transparent';

  const navBottomContainerStyle = 'flex flex-col ' + // Flex container, with child items arranged top->bottom
    'w-full ' + // Flex container, with child items arranged top->bottom
    'bg-transparent'; // Items are aligned to the end of the container

  const navButtonStyle = 'w-10 h-8 mx-1 ' + // Width and height of the button
    'rounded-lg ' + // Rounded corners
    'cursor-pointer ' + // Cursor changes to pointer when hovering over the button
    'bg-transparent z-20' // Background color of the button

  const navItemStyle = 'w-full h-12 ' + // Take up the full width of parent container
    'ml-2 ' + // Margin to the left and right of the item
    'text-left text-dull-white text-xl font-liberation-mono tracking-wide ' + // Button text formatting
    'cursor-pointer ' + // Cursor changes to pointer when hovering over the item
    'bg-transparent ' + // Background color of the item
    'select-none ' + // Text selection is disabled
    'hover:scale-105 transition-transform duration-300 ' + 
    'hover:bg-purple-base hover:bg-opacity-20'; // Background color of the item when hovered over

  const createButtonStyle = 'w-full h-10 mx-3 my-2 ' + 
    'bg-purple-base bg-opacity-80 ' + // Background color of the button
    'hover:bg-opacity-70 ' + // Background color of the button when hovered over
    'text-center text-dull-white text-2xl font-bold font-liberation-mono tracking-wide ' + // Button text formatting
    'rounded-xl ' + // Rounded corners
    'cursor-pointer select-none'; // Cursor changes to pointer when hovering over the button

  const profileCardContainerStyle =  'w-full mb-0.5 ' + 
                                     'hover:scale-[1.02] transition-transform duration-300 ' + // Scaling effect, on mouse hover
                                    `${(User != null && !isLogoutCollapsed) ? "hover:bg-purple-base hover:bg-opacity-20" : "" }` //Background color of the container, when hovered over

  // #endregion

  return (
	<animated.div id='Navbar-top'
                style={navBarHeightAnimation}
                className={navBarStyle}>

        <div id='Border-container'
             className={borderContainerStyle}
             style={{filter: 'brightness(110%)'}}>

              <div id='Nav-Content-container'
                   className='inline-flex'>
                    
                <button id='Nav-button'
                        className={navButtonStyle}
                        onClick={toggleNavBar}>
                          <IoMenuSharp size={36} color={Colors.NEON_PURPLE} />
                </button>

                <div>
                    <h1 className='text-white text-xl'>{width + "|" + height + " | " + mode}</h1>
                </div>
              </div>

              <>
                  <div id='Nav-Items-container'
                       style={{marginTop: '0.25rem'}}
                       className={navItemContainerStyle}>

                      <button id='Nav-Item-Universe'
                              className={navItemStyle}>
                        My Universe
                      </button>

                      <button id='Nav-Item-IdeaNursery'
                              className={navItemStyle}>       
                        My Idea Nursery
                      </button>

                      <button id='Nav-Item-ReaderTimeline'
                              className={navItemStyle}>
                        My Reader's Timeline
                      </button>

                      <button id='Nav-Item-Profile'
                              className={navItemStyle}>
                        My Profile
                      </button>

                      <button id='Nav-Item-Settings'
                              className={navItemStyle}>
                        My Settings 
                      </button>          
                </div>

                <div id='Nav-Bottom-Container' className={navBottomContainerStyle}>
            
                  <GradientButton buttonStyle={createButtonStyle}>
                      <div className='inline-flex '>
                        [Create✨]
                      </div>
                  </GradientButton> 

                <div id='profile-card-container' className={profileCardContainerStyle}>

                    { /* Display the User's Profile card, if the user has been authenticated our application */ }
                    { User && <UserProfileCard user={User}
                                               onLogoutPressed={onLogoutRequestReceived} 
                                               isParentCollapsed={!expandNavBarTrigger} 
                                               onProfileCardCollapse={(isCollapsed) => setIsLogoutCollapsed(isCollapsed)} />}

                    { /* Display the Log In button, if the user is not logged in */ }
                    { (User == null) && 

                        <div className='flex justify-center'>

                            <button id='Nav-Item-LogIn' className={createButtonStyle}
                                    onClick={ () => {
                                      toggleNavBar(); //hide the NavBar again
                                      navigate('/login'); //navigate to the login page
                                    }}>

                                <div className='inline-flex items-center'>
                                  <FaUserAlt className='mr-3'/> Sign in
                                </div>

                            </button>
                        </div>
                    } 
                  
                </div> {/* End of Profile-Card-Container */}

              </div> {/* End of Nav-Bottom-Container */}

            </>          

        </div>
	
	</animated.div>
  );
}

export default React.memo(NavBar);