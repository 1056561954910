import React, { useState } from "react";

import  { WINDOW_MODE } from './WindowInterface';
import PopupInterface from "./PopupInterface";
import { ExpansionContractionAnimationProps } from "../data types/mystarpath_types";

export default function LogoutConfirmation({ onInterfaceWindowClose } : { onInterfaceWindowClose: (shouldLogout : boolean) => void })
{
    console.log("(LogoutConfirmation) - Rendering!");

    // #region Variables

    const [closeLogoutInterfaceWindow, setCloseLogoutInterfaceWindow] = useState<boolean>(false); // State variable to force close the login interface
    const [shouldLogout, setShouldLogout] = useState<boolean>(false); // State variable to determine if the user should logout

    // #endregion

    // #region Helper Methods

    // Method that returns a TailwindCSS style, that's used to position the LogoutConfirmation component
    const getPanelPositioning = (_m: WINDOW_MODE) : string => {

        switch(_m) {
            case WINDOW_MODE.NORMAL:
            case WINDOW_MODE.HALVED:
                return 'left-1/2 top-1/2 '; //Positions the left edge of the element at 50% of the container's width, and translates the element left by 50% of its own width, effectively centering it horizontally.

            case WINDOW_MODE.MOBILE:
                return 'w-full h-full mt-5 mx-3 ';
        }
    }

    // Method that returns the width of the (expanded) LogoutConfirmation interface
    const getExpandedWidth = (_m: WINDOW_MODE, w: number) => { //TODO AARON - Change to a variable, that only recalculates when the window size changes! 

        switch(_m) {
            case WINDOW_MODE.NORMAL:
            case WINDOW_MODE.HALVED:
                return 575; //w * 0.30;
        }
    };

    // Method that returns the height of the (expanded) LogoutConfirmation interface
    const getExpandedHeight = (_m: WINDOW_MODE, h: number) => {

        switch(_m) {
            case WINDOW_MODE.NORMAL:
            case WINDOW_MODE.HALVED:
                return 250; //return h * 0.65;
        }
    };

    const handleNoButtonClicked = () => {

        // Close the Logout Confirmation interface
        setShouldLogout(false);
        setCloseLogoutInterfaceWindow(true);
    };

    const handleYesButtonClicked = () => {

        // Close the Logout Confirmation interface
        setShouldLogout(true);
        setCloseLogoutInterfaceWindow(true);
    };

    // Callback method that's called when the PopupInterface's animation has finished
    const handleAnimationFinished = (type: ExpansionContractionAnimationProps) => {

        // Don't do anything if the logout window has just opened
        if(type == ExpansionContractionAnimationProps.EXPAND)
            return;

        console.log("(LogoutConfirmation) - Interface window has finished animating - calling parent to finish closing process!");
        onInterfaceWindowClose(shouldLogout); //Let the caller know that our window has closed, and if we should logout now
    };

    // #endregion

    // #region TailwindCSS Styles

    const logoutDisplayContainerStyle = 'flex flex-wrap text-center w-3/4 h-1/3 mx-auto mt-6 ' +  // Container that holds the window's title
                                        'text-3xl font-nidus-regular tracking-wider text-dull-white pointer-events-none select-none ';

    const buttonStyle = "w-40 h-1/4 mx-3 text-dull-white font-nidus-regular text-2xl hover:font-bold hover:text-selection-color " + // The TailWindCSS style for the "Yes" and "No" buttons on the page
                        "select-none " + // Text selection is disabled
                        "hover:scale-110 transition-transform duration-150 "; // "Expansion effect on mouse hover for the button style"


    // #endregion

    return (

        <PopupInterface getInterfacePosition={getPanelPositioning}
                        getExpandedWidth={getExpandedWidth}
                        getExpandedHeight={getExpandedHeight}
                        borderColor="border-error-red"
                        closeInterface={closeLogoutInterfaceWindow}
                        animationFinished={handleAnimationFinished}>

            {/* The Title of the Confirmation Window */}
            <div className={logoutDisplayContainerStyle}>
                Are you sure you want to logout?
            </div>

            <div className="flex inline flex justify-center items-center w-full h-1/4 mt-12">

                <button id="no-button" 
                        className={buttonStyle}
                        onClick={handleNoButtonClicked}>
                    [ No ]
                </button>

                <button id="yes-button" 
                        className={buttonStyle}
                        onClick={handleYesButtonClicked}>
                    [ YES ]
                </button>  

            </div>     

        </PopupInterface>
    );
}