import { User } from "../data types/mystarpath_types";
import { MONGO_DB_RESPONSE_TYPES } from "../data types/mystarpath_types";

export async function addUser(user_data: User) : Promise<User> {

    const isInProduction = import.meta.env.MODE === 'production';
    const endpoint = (isInProduction) ? import.meta.env.VITE_API_PROD_ENDPOINT : 
                                        import.meta.env.VITE_API_DEV_ENDPOINT;
                                        
    try {      
        const auth_sub = user_data.getAuth0Sub();
        const auth_type = user_data.getAuthType();

        // Call our getUser API endpoint, with the user's sub ID
        const response = await fetch(endpoint + `/addUser?auth0_provider=${auth_type}&auth0_sub=${auth_sub}` + 
                                     `&username=${user_data.username}&email=${user_data.email}&name=${user_data.name}&picture=${user_data.picture}`);

        // Parse the response as JSON
        switch(response.status) {

            case 200: // Success case
            // TODO!!
            break;

            case 201: // User added successfully
                const data = await response.json();

                // Extract the user data from the response
                const newUser = new User(data.auth0_type, data.auth0_sub, data.username, 
                                         data.email, data.name, data.picture, data._id, data.created_at);

                return newUser;

            default:
                throw new Error(MONGO_DB_RESPONSE_TYPES.USER_GET_FAILED);
        }
    }
    catch(error) {
        console.error("(addUser_service) - An unexpected error has occurred: " + error);
        throw new Error(MONGO_DB_RESPONSE_TYPES.USER_GET_FAILED);// Rethrow the error back to caller
    }
};