
/**
 * Generates a random state string of a specified length using URL-safe characters.
 *
 * @param {number} [length=32] - The length of the generated state string. Defaults to 32 if not provided.
 * @returns {string} A randomly generated state string.
 */
export function generateRandomState(length = 32) : string {
    // Define a set of characters that are URL-safe
    const possibleChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_';
    
    // Create a typed array for random values
    const randomValues = new Uint8Array(length);
    window.crypto.getRandomValues(randomValues);
    
    // Map each random byte to one of the possible characters
    let state = '';
    for (let i = 0; i < length; i++) {
      state += possibleChars.charAt(randomValues[i] % possibleChars.length);
    }
    
    return state;
  }

/**
 * Generates a code challenge from a given code verifier using the SHA-256 hashing algorithm.
 *
 * @param codeVerifier - The code verifier string to be hashed.
 * @returns A promise that resolves to the base64 URL encoded string of the SHA-256 hash of the code verifier.
 */
export async function generateCodeChallenge(codeVerifier)  : Promise<string>
{
const encoder = new TextEncoder();
const data = encoder.encode(codeVerifier);
const digest = await window.crypto.subtle.digest('SHA-256', data);

return base64UrlEncode(new Uint8Array(digest));
}

/**
 * Generates a code verifier for PKCE (Proof Key for Code Exchange) authentication.
 * 
 * This function creates a random 32-byte array using the Web Crypto API and encodes it
 * in a URL-safe base64 format.
 * 
 * @returns {Promise<string>} A promise that resolves to a base64 URL-encoded string.
 */
export async function generateCodeVerifier() {
    const array = new Uint8Array(32);
    window.crypto.getRandomValues(array);

    return base64UrlEncode(array);
  }

// Generate a code_verifier:
export function generatePlainCodeVerifier(length = 43) {
  // Per RFC 7636, a code_verifier is a high-entropy cryptographic random string 
  // using the characters [A-Z]/[a-z]/[0-9]/"-"/"."/"_"/"~".
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
  let verifier = '';
  const randomArray = new Uint8Array(length);
  window.crypto.getRandomValues(randomArray);
  for (let i = 0; i < length; i++) {
    verifier += chars[randomArray[i] % chars.length];
  }
  return verifier;
}

/**
 * Encodes a given buffer to a Base64 URL-safe string.
 *
 * This function converts a buffer to a Base64 string and then makes it URL-safe
 * by replacing characters that are not URL-safe with their URL-safe equivalents.
 * Specifically, it replaces '+' with '-', '/' with '_', and removes any trailing '=' characters.
 *
 * @param buffer - The buffer to be encoded.
 * @returns The Base64 URL-safe encoded string.
 */
function base64UrlEncode(buffer) : string {
return btoa(String.fromCharCode.apply(null, buffer))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');
}