// #region Types and Imports

import React, { useEffect, useState } from "react";
import { animated, useChain, useSpring, useSpringRef } from "@react-spring/web";

import { ExpansionContractionAnimationProps } from "../data types/mystarpath_types";
import useWindowSize, { WINDOW_MODE } from "./WindowInterface";

type PopupInterfaceProps = {

    getInterfacePosition (mode: WINDOW_MODE): string; // Function to give this interface, that controls where it should be positioned, based on the window mode
    getExpandedWidth (mode: WINDOW_MODE, w: number): number; // Function to give this interface, that controls how wide it should render, based on the parameters given
    getExpandedHeight (mode: WINDOW_MODE, h: number): number; // Function to give this interface, that controls how wide it should render, based on the parameters given
    animationFinished: (type?: ExpansionContractionAnimationProps) => void;
    closeInterface: boolean; // Determines if the interface should be closed
    borderColor?: string; // Optional color to use for the interface border
    renderStyle?: string; // Optional style to apply to the interface
    children: React.ReactNode;
};

// #endregion

export default function PopupInterface({ getInterfacePosition, getExpandedWidth, getExpandedHeight, animationFinished,
                                         closeInterface, children, renderStyle, borderColor = "border-neon-purple"} : PopupInterfaceProps) {

    console.log("(PopupInterface) - Rendering!");

    // #region React Hooks
    
    const { width, height, mode } = useWindowSize(); // Hook to get the window size
    const [showContent, setShowContent] = useState<boolean>(false); // State variable to determine if the content should be displayed
    const [expandPageTrigger, setExpandPageTrigger] = useState<boolean>(false); // Controls the growing/shrinking animation of the Login Page

    // #endregion

    // #region Animations

    const heightAnimationRef = useSpringRef();
    const widthAnimationRef = useSpringRef();
    const animationDuration = 200; // Time in milliseconds for the Login Page to expand

    const widthAnimation = useSpring({
        ref: widthAnimationRef,
        width: expandPageTrigger ? getExpandedWidth(mode, width) : 0,
        from: { width: 0  }, // Initial collapsed height of the NavBar
        config: { duration: animationDuration },

        onRest: () => {

            if(expandPageTrigger) {
                console.log("(PopupInterface) - Expansion animation has finished!");
                setShowContent(true);
                animationFinished(ExpansionContractionAnimationProps.EXPAND);
            }   
        }

    });

    const heightAnimation = useSpring({
        ref: heightAnimationRef,
        height: expandPageTrigger ? getExpandedHeight(mode, height) : 0,
        from: { height: 0  }, // Initial collapsed height of the NavVar
        config: { duration: animationDuration },

        onRest: () => {

            if(!expandPageTrigger) {
                console.log("(PopupInterface) - Contraction animation has finished!");
                animationFinished(ExpansionContractionAnimationProps.CONTRACT);
            }   
        }
    });

    // Use the useChain hook to chain the animations together. 
    // [0, 0.5] means that the first animation will start at 0 seconds, and the second animation will start at 0.5 seconds.
    useChain(expandPageTrigger ? [heightAnimationRef, widthAnimationRef] : [widthAnimationRef, heightAnimationRef], 
        [0, expandPageTrigger ?  0.4 : 0.25]);

    // #endregion

    // #region React Effects

    // Effect that triggers when the closeInterface state variable changes
    useEffect(() => {

        if(closeInterface) {
            
            console.log("(PopupInterface) - Contracting the Popup Interface out of view!");
            setShowContent(false);
            setExpandPageTrigger(false); // trigger the contracting animation of the Login Page
        }

    }, [closeInterface]); // Effect that renders only when the closeInterface state variable changes

    useEffect(() => {   

        console.log("(PopupInterface) - Expanding the Popup Interface into view!");
        setExpandPageTrigger(true); // trigger the expanding animation of the Login Page

    }, []); // Effect that renders only on component mount, and unmount

    // #endregion

    // #region Tailwind Styles

    renderStyle = renderStyle ?? 'absolute z-50 ' + // Absolutely positioned at the top of the page
                                 getInterfacePosition(mode) +  // Positioning of the panel
                                 'transform -translate-x-1/2 ' + // Center the panel horizontally
                                 'transform -translate-y-1/2 ' + // Center the panel vertically
                                 `bg-dark-purple-background border-2 rounded-lg ${borderColor}`; // Background color of the panel
    // #endregion

    //#region JSX Return

    return (

        <animated.div id='Login-page-container'
                      className={renderStyle}
                      style={{...widthAnimation,
                              ...heightAnimation}}>

            { showContent && 
                <> { children } </>   
            }

        </animated.div>

    );

    // #endregion

}