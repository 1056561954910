import { c } from "vite/dist/node/types.d-aGj9QkWt";
import { Auth0ExchangeCodeResponse, Auth0ProviderResponseType, SocialAuthType } from "../data types/mystarpath_types";

export async function exchangeAuthorizationCode(code: string, provider: SocialAuthType, verifier: string) : Promise<Auth0ExchangeCodeResponse>
{
    const isInProduction = import.meta.env.MODE === 'production';
    const endpoint = (isInProduction) ? import.meta.env.VITE_API_PROD_ENDPOINT : import.meta.env.VITE_API_DEV_ENDPOINT;
    const subfetch = (provider === SocialAuthType.GOOGLE) ? `/exchangeAuthorizationCode?authorizationCode=${code}`
                                                          : `/exchangeAuthorizationCode?authorizationCode=${code}&verifier=${verifier}`; // TODO, verify the value of verifier, when pkce_code_verifier isn't around!

    console.log("(exchangeAuthorizationCode_serivce) - Calling exchangeAuthorizationCode endpoint, for provider: " + provider);

    try 
    {
        // Create a custom fetch request, based on the provider we want to exchange our code w/, for an access token
        const fetch_request = endpoint + subfetch;
        // Call our exchangeAuthorizationCode serverless API endpoint, with our authorization code
        const response = await fetch(fetch_request);

        // Parse the response as JSON
        switch(response.status) {

            case 200: // Success case
                const data = await response.json();
                console.log(data);

                switch(provider) {
                    case SocialAuthType.GOOGLE:
                        if(!data.access_token || !data.id_token) {
                            console.error("(exchangeAuthorizationCode.js) - Missing tokens in response from Google!");
                            throw new Error(Auth0ProviderResponseType.USERDATA_MISSING_TOKENS);
                        }

                        // Return the access and id tokens
                        return { access_token: data.access_token,
                                 id_token: data.id_token
                        };

                    case SocialAuthType.TWITTER:
                        if(!data.access_token || !data.refresh_token) {
                            console.error("(exchangeAuthorizationCode.js) - Missing tokens in response from X.com!");
                            throw new Error(Auth0ProviderResponseType.USERDATA_MISSING_TOKENS);
                        }

                        // Return the access and refresh tokens
                        return { access_token: data.access_token,
                                 refresh_token: data.id_token
                        };

                    default:
                        throw new Error(Auth0ProviderResponseType.EXCHANGE_CODE_FAILURE);
                }

            default:
                throw new Error(Auth0ProviderResponseType.EXCHANGE_CODE_FAILURE);
        }
    }
    catch(error) {
        console.error("An unexpected error was encountered in exchangeAuthorizationCode_service.ts");
        throw error; // Rethrow the error back to caller
    }

}