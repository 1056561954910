import { BufferGeometry, Float32BufferAttribute, PointsMaterial, TextureLoader, Vector3 } from "three";
import { useThree } from "@react-three/fiber";
import React, { useState, useMemo } from "react";

import starMap from '/assets/backgrounds/starfield_template_background.png';
import { vec3 } from "../data types/mystarpath_types";

/**
 * Generates a starfield within the camera's viewing frame.
 * 
 * @param numberOfStars - The number of stars to generate.
 * @param size - The size of the starfield.
 * @param center - The center point of the starfield.
 * @param exclusionRange - The range within which stars should be excluded.
 * @param camera_fov - The field of view of the camera.
 * @param camera_far_plane - The far plane of the camera.
 * @param camera_near_plane - The near plane of the camera.
 * @param aspectRatio - The aspect ratio of the camera.
 * 
 * @returns The generated starfield as a BufferGeometry.
 */
export function generateSpacefield(numberOfStars: number, center: vec3, exclusionRange: number,
    camera_fov: number, camera_far_plane: number, camera_near_plane: number, aspectRatio: number) : BufferGeometry
{

    console.log("(Spacefield) - Now generating spacefield!");

    const starGeometry = new BufferGeometry();
    const starVertices: Array<number> = [];
    const starCoordinate = new Vector3();
    const originPoint = new Vector3(center[0], center[1], center[2]);
    const distanceBetweenVectors = new Vector3();

    const halfCameraFOV = (camera_fov * (Math.PI / 180)) / 2;  // Convert the camera's FOV to radians, and divide by 2
    const frustumHeight = 2 * (camera_far_plane * Math.tan(halfCameraFOV));
    const frustumWidth = frustumHeight * 2.002; //aspectRatio;

    let distance = 0;
    let z_coordinate = 0;

    // Create 'numberOfStars' stars, from the origin point of the camera!
    for (let i = 0; i < numberOfStars; i++) 
    { 
        // First, generate a random star coordinate, with a random "depth" from our origin point
        z_coordinate = (Math.random() - 0.25) * (camera_far_plane - camera_near_plane) + camera_near_plane + 50;

        // Generate a random star coordinate, within the viewing frame of the camera
        starCoordinate.set((Math.random() - 0.5) * frustumWidth + originPoint.x,
            (Math.random() - 0.5) * frustumHeight + originPoint.y, 
            z_coordinate);

        distance = distanceBetweenVectors.set(center[0], center[1], center[2]).distanceTo(starCoordinate); // Calculate the distance between the star and the center

        if(distance <= exclusionRange) 
        { 
            // skip to the next iteration, if the star is too close to the sphere of the forbidden range
            i--;
            continue;
        }

        starVertices.push(starCoordinate.x, starCoordinate.y, starCoordinate.z);
    }

    starGeometry.setAttribute('position', new Float32BufferAttribute(starVertices, 3));

    return starGeometry;
}

export default function SpaceField()
{
    const { camera, size } = useThree();
    const starSize = 0.7;
    const starsToGenerate = 3000;
    const originPoint: vec3 = [0,0,0];
    const forbiddenRange = 25;

    const [starGeometry] = useState<BufferGeometry>(() => { //Run initializing function only once, when the component is first rendered

        return generateSpacefield(starsToGenerate, originPoint, forbiddenRange,
                                  20, camera.far, camera.near, size.width / size.height);
        });

                                  
    const starMaterial = useMemo(() => { 
        console.log("(Spacefield) - Loading star texture!");

        return new PointsMaterial({color: 0xffffff, // TODO - Handle error case for TextureLoader onError condition! 
                                    size: starSize, 
                                    alphaTest: 0.5,
                                    transparent: true,
                                    sizeAttenuation: true,
                                    map: new TextureLoader().load(starMap)})}, []);

    return (     

        <points geometry={starGeometry} 
                material={starMaterial} />
    )
}