import { User } from "../data types/mystarpath_types";
import { MONGO_DB_RESPONSE_TYPES } from "../data types/mystarpath_types";

export async function getUser(user_data: User) : Promise<User> {

    const isInProduction = import.meta.env.MODE === 'production';
    const endpoint = (isInProduction) ? import.meta.env.VITE_API_PROD_ENDPOINT : 
                                        import.meta.env.VITE_API_DEV_ENDPOINT;
                                        
    try {

        console.log("(getUser_service) - Creating fetch request, to get thje user from the database!");
        
        // Call our getUser API endpoint, with the user's sub ID
        const response = await fetch(endpoint + `/getUser?type=${user_data.getAuthType()}&sub=${user_data.getAuth0Sub()}`);

        // Parse the response as JSON
        switch(response.status) {

            case 200: // Success case
                const data = await response.json();
                return User.fromDatabaseJSON(data);

            case 400:
                throw new Error(MONGO_DB_RESPONSE_TYPES.USER_PARAMETER_MISSING_SUB);

            case 404:
                console.log("(getUser_service) - User not found in database");
                return null;

            default:
                throw new Error(MONGO_DB_RESPONSE_TYPES.INTERNAL_SERVER_ERROR);
        }
    }
    catch(error) {
        console.error("(getUser_service) - An unexpected error has occurred: " + error);
        throw new Error(MONGO_DB_RESPONSE_TYPES.USER_GET_FAILED);// Rethrow the error back to caller
    }
};