import React, { useContext, useImperativeHandle, useMemo, useState, useRef, useEffect, forwardRef } from 'react';
import { AmbientLight, Fog } from 'three';
import { Canvas } from '@react-three/fiber';
import { useNavigate } from "react-router-dom";

import { FlyControls, OrbitControls } from '@react-three/drei';

import NavBar from '../../library/NavBar';
import LoginPage from '../../LoginPage';
import { UserManagementContext } from '../../data_providers/UserManagement';
import NavigationHelper_Galaxy, { NavigationContext }  from './data_providers/NavigationHelper_Galaxy';
import IntroductionLoadingScreen from './components/IntroductionLoadingScreen';
import CameraController_Galaxy, { CameraControllerRef } from './components/CameraController_Galaxy';
import PlanetManager from './components/PlanetManager';
import { AnimationType_GalaxyScene, SceneInterface, RouteType } from '../../data types/mystarpath_types';
import LensFlare from './components/LensFlare_Galaxy';
import Nebula from '../Hyperspace/components/Nebula';
import LogoutConfirmation from '../../library/LogoutConfirmation';


// #region Types and Interfaces

export type GalaxySceneInterface = {
    
    openLoginPage: () => void;
    goHome: (action?: GalaxySceneActionItems) => void;
};

export enum GalaxySceneActionItems {
    
    OPEN_LOGIN_PAGE,
    SHOW_LOGIN_SUCCESS_MESSAGE,
}

type GalaxySceneProps = {

    actions?: GalaxySceneActionItems

};

// #endregion

const GalaxyScene = React.memo(forwardRef<GalaxySceneInterface, GalaxySceneProps>(({ actions = null}, ref) => {

    console.log("(GalaxyScene) - Rendering!");

    // #region Data Providers

    const UserProvider = useContext(UserManagementContext); // The user management provider context
    const navigationHelper = useMemo(() => new NavigationHelper_Galaxy(), []); // The navigation helper object

    // #endregion

    // #region State Variables

    const navigate = useNavigate(); // Hook to navigate to different pages
    const [sceneReady, setSceneReady] = useState<boolean>(false); // State variable to determine if the scene is ready to be displayed
    const [showModalScreen, setShowModalScreen] = useState<boolean>(false); // State variable to determine if the modal screen should be displayed
    const [showLoginPage, setShowLoginPage] = useState<boolean>(false); // State variable to determine if the login page should be displayed
    const [showLogoutConfirmation, setShowLogoutConfirmation] = useState<boolean>(false); // State variable to determine if the logout confirmation page should be displayed

    // #endregion

    // #region Introduction Loading Screen

    const [loadingScreenVisible, setLoadingScreenVisible] = useState<boolean>(true); // State variable to control the fade out animation of the IntroductionLoadingScreen

    // #endregion

    // #region Camera Controller

    const cameraControllerRef : React.MutableRefObject<CameraControllerRef> = useRef(null); // Reference to the CameraController_Galaxy component

    // #endregion

    // #region Event Handlers

    const handleAnimationTrigger = (animation : AnimationType_GalaxyScene) => {
    
    }

    const handleLoginPage_Open = () => {

        console.log("(GalaxyScene) - Opening the Login Page!");
        setShowLoginPage(true);
    }

    const handleLoginOpen_AnimationFinished = () => {

        if(showModalScreen)
        {
            // If the modal screen is visible, then hide the login screen
            setShowLoginPage(false);
            navigate(RouteType.HOME_PAGE);
        }

        setShowModalScreen(!showModalScreen); //Toggle the modal screen
    };

    // Helper method to handle to request to display the logout modal page, from the NavBar
    const handleLogoutRequest = () => {

        console.log("(GalaxyScene) - Received the request to display the Logout Modal Page!");

        setShowLogoutConfirmation(true);
        setShowModalScreen(true);
    }

    // Helper method to process the Logout window closing, and take further action if the currently user needs to be logged otu
    const handleLogoutWindowClose = (shouldLogout: boolean) => {

        if(shouldLogout){      
            console.log("(GalaxyScene) - Logging out the user!");   
            UserProvider.logoutUser();
        }

        // Close the Interface screen and Modal window
        setShowLogoutConfirmation(false);
        setShowModalScreen(false);
    };

    // #endregion

    // #region React Effects

    // Effect to handle the actions that are passed to the scene, when the scene is first ready to be displayed
    useEffect(() => {

        // Don't evaluate initial actions, until the scene is ready!
        if(!sceneReady)
            return;

        if(actions != null) {

            switch(actions) {

                case GalaxySceneActionItems.OPEN_LOGIN_PAGE:
                    handleLoginPage_Open();
                    break;

                case GalaxySceneActionItems.SHOW_LOGIN_SUCCESS_MESSAGE:
                    console.log("(GalaxyScene) - Showing the Login Success Message!");
                    console.log("(GalaxyScene) - User: ", UserProvider.getAuthenticatedUser());
                    break;

                default:
                    console.error("(GalaxyScene) - Unrecognized action: ", actions);
            }
        }

    }, [sceneReady]);

    // #endregion

    // #region Outside Handler Methods

    useImperativeHandle(ref, () => ({

        openLoginPage: () => { 
            
            console.log("(GalaxyScene) - Received the command to open the Login Page!");
            handleLoginPage_Open();
        },

        goHome: () => {
            console.log("(GalaxyScene) - Received the command to go home!");
            
            // Currently, nothing to do! The Login Page handles its own closing animation, which is also handled by
            // the handler method handleLoginOpen_AnimationFinished(), here. 
        },

    }));

    // #endregion

    // #region JSX Return

    return (
        <>
            { (sceneReady == false) &&

                <IntroductionLoadingScreen loadingScreenVisible={ loadingScreenVisible }
                                           onFadeOutFinished={() => setSceneReady(true) } />        
            }
  
            <div id='galaxy-scene' className='fixed top-0 left-0 w-full h-full bg-black'>

                {/* Conditionally rendered components */ }
                { showLoginPage && <LoginPage animationFinished={ handleLoginOpen_AnimationFinished } /> }
                { showModalScreen && <div className='fixed top-0 left-0 w-full h-full bg-black bg-opacity-70 z-30'></div> }
                { showLogoutConfirmation && <LogoutConfirmation onInterfaceWindowClose={handleLogoutWindowClose} /> }

                {/* Main content */ }
                <div id="Non-Modal"
                     className='fixed top-0 left-0 w-full h-full'>

                    <NavBar onLogoutRequestReceived={handleLogoutRequest}/>

                    <NavigationContext.Provider value={navigationHelper}>         
                        <Canvas frameloop="demand">

                            <OrbitControls />

                            <CameraController_Galaxy ref={cameraControllerRef} 
                                                    onAnimationEnd={(animation) => handleAnimationTrigger(animation)} >

                                <PlanetManager onAssetsLoaded={() => setLoadingScreenVisible(false) /* fade out the initial loading screen */ } />

                            </CameraController_Galaxy>

                        </Canvas>
                    </NavigationContext.Provider>

                </div>

            </div>

        </>
    )

    // #endregion
}));

export default GalaxyScene;

/*                        <ambientLight intensity={0.5} color={0xffffff} />

                        <fog attach="fog" args={[0x000000, 50, 600]} />
*/