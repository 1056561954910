import React, { useState } from "react";
import { useSpring, animated } from "@react-spring/web";

import { Colors } from "../data types/mystarpath_types";

export default function GradientButton({buttonStyle, children} : {buttonStyle: string, children: React.ReactNode}) 
{
    const _style = 'text-center text-dull-white text-2xl font-bold font-liberation-mono tracking-wide'; // Button text formatting
    const [highlightColor, setHighlightColor] = useState<string>( "#ffffff"); // The color of the button's highlight


  // Animation using react-spring
  const gradientAnimation = useSpring({
    from: { backgroundPosition: "0% 0%" }, // Start at the beginning of the gradient
    to: { backgroundPosition: "200% 0%" }, // Move it across the full width of the gradient
    config: { duration: 6000 },
    loop: true,
  });

  return (

    <div className="relative flex items-center justify-center h-11 mx-2 my-2 w-full bg-transparent" // Gradient Border 
          onMouseEnter={() => setHighlightColor(Colors.SELECTION_YELLOW)}
          onMouseLeave={() => setHighlightColor("#ffffff")}> 

        <div className="absolute mb-1 text-center rounded-lg">
            <animated.button className={`${_style} bg-clip-text hover:scale-105 transition-transform duration-300`}
                            style={{ ...gradientAnimation,
                                    backgroundImage: `linear-gradient(90deg, #d400f3, #7600ef, ${highlightColor}, #d400f3)`, // Repeating gradient pattern
                                    backgroundSize: "200%", // Ensures smooth looping (twice the width of the button)
                                    WebkitBackgroundClip: "text", // Ensure that the gradient is clipped to the text
                                    WebkitTextFillColor: "transparent", // Ensure that the text is transparent, and ensure the gradient pattern repeats seamlessly
                                    backgroundRepeat: "repeat" }}> 

                { children }

            </animated.button>
        </div>

    </div>

  );
};

//     <div className="relative h-11 mx-2 my-2 w-full bg-transparent"> {/* Gradient Border */}

//     <animated.div className="absolute w-full h-full rounded-lg" // Outer border container
//     style={{ ...gradientAnimation,
//             backgroundImage: "linear-gradient(90deg, #d400f3, #7600ef, #ffffff, #d400f3)", // Repeating gradient
//             backgroundSize: "200%",
//      }} />


// <div className="absolute inset-[0.05rem] text-center bg-black rounded-lg">
// <animated.button className={`${_style} bg-clip-text hover:scale-105 transition-transform duration-300`}
//           style={{ ...gradientAnimation,
//                   backgroundImage: "linear-gradient(90deg, #d400f3, #7600ef, #ffffff, #d400f3)", // Repeating gradient pattern
//                   backgroundSize: "200%", // Ensures smooth looping (twice the width of the button)
//                   WebkitBackgroundClip: "text", // Ensure that the gradient is clipped to the text
//                   WebkitTextFillColor: "transparent", // Ensure that the text is transparent, and ensure the gradient pattern repeats seamlessly
//                   backgroundRepeat: "repeat" }}> 

// { children }

// </animated.button>
// </div>

// </div>


