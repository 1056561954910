import React, { useState } from 'react'
import { animated, useSpring } from '@react-spring/web'
import { HiDotsVertical } from "react-icons/hi";
import { FaUserAlt } from "react-icons/fa";

import { UserManagementContext } from '../data_providers/UserManagement';
import { Colors, User } from '../data types/mystarpath_types';

export default function UserProfileCard({user, isParentCollapsed, onProfileCardCollapse, onLogoutPressed} : { user: User, isParentCollapsed: boolean, 
                                                                                                              onProfileCardCollapse: (isCollapsed: boolean) => void
                                                                                                              onLogoutPressed: () => void }) {
    //#region Variables

    const [isCollapsed, setIsCollapsed] = useState<boolean>(false); // State variable to determine if the user's profile card is collapsed (showing the 'logout' button, if collapsed)
    const [logoutEntered, setLogoutEntered] = useState<boolean>(false); // State variable to determine if the user's mouse is hovering over the logout button
    const usePicture = (user.picture && user.picture.length > 0); // We should only use the user's picture, if they have something to display 

    console.log("User Profile Card: ");
    console.log(user);

    //#endregion

    //#region Animations

    // Sliding panel animation
    const slidingPanel = useSpring({
        transform: isCollapsed ? 'translateX(0%)' : 'translateX(100%)', // Slide left to reveal Logout, slide right to show name+username
    });

    const textFade = useSpring({
        opacity: isCollapsed ? 0.05 : 1, // Fade out username + name, when collapsed
      });

    const dotsIconFade = useSpring({
        opacity: isCollapsed ? 0 : 1, // Fade out the dots icon completely when collapsed
    });
    
    const logoutFade = useSpring({
    opacity: isCollapsed ? 1 : 0, // Fade-in the logout button, when collapsed
    });

    //#endregion

    // #region React Effects

    // Effect that triggers when the user's profile card is expanded/collapsed, by button click
    React.useEffect(() => {
        onProfileCardCollapse(isCollapsed);
    }, [isCollapsed]);

    // Effect that triggers when the parent component collapses the user's profile card
    React.useEffect(() => {

        if(isParentCollapsed && isCollapsed) 
            setIsCollapsed(false);

    }, [isParentCollapsed]);

    // #endregion

    return (

        // Container button 
        <button className='relative flex w-full pr-3 overflow-hidden' 
                onClick={() => setIsCollapsed(!isCollapsed)}> 

            {/* Display the user's picture, if they have one */}
            {usePicture && <img src={user.picture} 
                                alt='Profile Picture' 
                                className='w-12 h-12 mr-3 ml-1 mb-1 rounded-full object-cover' />} 

            {/* Otherwise, use a default icon */}
            {!usePicture && <FaUserAlt className='w-12 h-12' />} 


            {/* Sliding panel */}
            <animated.div style={slidingPanel}
                          className="absolute z-50 top-0 left-0 h-full w-full flex items-center justify-end bg-black bg-opacity-80">

                {/* Logout Button */}
                <button onMouseEnter={() => setLogoutEntered(true)}
                        onMouseLeave={() => setLogoutEntered(false)}
                        onClick={onLogoutPressed}
                        className={`w-17 h-7 text-left mr-3 text-lg font-liberation-mono font-bold ${logoutEntered ? "text-red-400" : "text-red-500"}`}>

                        Logout

                </button>
                
            </animated.div>
            
            {/* User Information */}
            <div className='relative flex-grow'> {/* Ensure that the text takes up available space, and pushes HiDotsVertical icon to the right */}

                <animated.div style={textFade} 
                              className="text-left">

                    <div className='text-white text-sm font-bold'>{user.name}</div>
                    <div className='text-dull-white text-xs'>{`@${user.username}`}</div>

                </animated.div>

            </div>

            {/* "More Options" icon*/}
            <animated.div style={dotsIconFade}>

                <HiDotsVertical size={24} color={Colors.DULL_WHITE} className='mt-1.5 text-dull-white' /> 

            </animated.div>
            
        </button>
    )
}