// #region Types and Imports

import React from "react";
import { useRouteError } from "react-router-dom";
import { useSpring, animated } from "@react-spring/web";
import { Canvas } from "@react-three/fiber";

import SpaceField from "../../library/SpaceField";
import { SceneName, vec3 } from "../../data types/mystarpath_types";

export type RouteErrorType = {

    statusText: string,
    message: string
}

// #endregion

export default function ErrorScene({ error, previousScene }: { error?: RouteErrorType, previousScene: SceneName }) 
{
    error = error ?? useRouteError() as RouteErrorType; // The error to display
    let backgroundColor: string;; // Background color of the scene

    // #region Animations

    const fadeIn = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 },
        config: { duration: 2000 }
    });

    // #endregion

    // #region TailwindCSS Styles

    switch(previousScene) {

        case SceneName.NONE:
            backgroundColor = "black"
            break;

        case SceneName.OAUTH_CALLBACK_SCENE:
            backgroundColor = "background-gray"
            break;
    }

    // #endregion
    
    return (
        <>
        {/* div element that contains the background color of the previous page, if configured */}
        <animated.div id="background-color-div"                
                      className={'z-25 absolute w-full h-full bg-space-background'}
                      style={fadeIn}>

            <div id='canvas-holder'
                className="z-5 absolute w-full h-full text-white bg-transparent"> 

                <h1>{error.statusText}</h1>
                <p>{error.message}</p>

            </div>

            <Canvas>
                <SpaceField />
            </Canvas>

        </animated.div>

        </>
    );

}

/*
            <Canvas>
                <SpaceField />      
            </Canvas>
            */