// #region Imports and Interfaces

import React, { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import { RouteType, User } from "../data types/mystarpath_types";

// Methods and properties that the UserManagement implement, to expose to the rest of the application
export interface UserManagementInterface {

    getAuthenticatedUser(): User;
    logoutUser(): void;
    setAuthenticatedUser(user: User): void;
}

type UserManagementProps = {
    children: React.ReactNode;
}

export const UserManagementContext = createContext<UserManagementInterface>(null);

// #endregion

export default function UserManagement({ children }) 
{
    console.log("(UserManagement) - Rendering!");

    // #region State Variables and References

    const navigate = useNavigate(); // Hook to navigate to different pages
    const [authenticatedUser, setAuthenticatedUser] = useState<User>(null); // The currently authenticated user

    // #endregion

    // #region Effects



    // #endregion

    // #region Helper Methods

    const logout = () => {

        console.log("(UserManagement) - Logging out the user!");
        setAuthenticatedUser(null);

        // Redirect the user to the home page
        navigate(RouteType.HOME_PAGE);
    };

    // #endregion

    // #region Provider Methods

    const provision = React.useMemo(() => ({

        getAuthenticatedUser: () => authenticatedUser,
        logoutUser: () => logout(),
        setAuthenticatedUser: (_user: User) => setAuthenticatedUser(_user),

    }), [authenticatedUser]);

    // #endregion

    return (

        <UserManagementContext.Provider value={ provision }>
            {children}
        </UserManagementContext.Provider>

    )
};