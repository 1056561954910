import { Auth0ProviderResponseType, Auth0ExchangeCodeResponse, MONGO_DB_RESPONSE_TYPES, SocialAuthType, User } from "../data types/mystarpath_types";

export async function getUserSocialProfile(token: Auth0ExchangeCodeResponse, provider: string): Promise<User> 
{
  let fetchRequest = '';

  try {
    const isInProduction = import.meta.env.MODE === 'production';
    const endpoint = (isInProduction) ? import.meta.env.VITE_API_PROD_ENDPOINT : import.meta.env.VITE_API_DEV_ENDPOINT;

    switch (provider) {

      case SocialAuthType.GOOGLE: // Use Google's API to get the user's profile
        fetchRequest = endpoint + `/getSocialProfileGoogle?access_token=${token.access_token}`;
        break;

      case SocialAuthType.TWITTER:
        fetchRequest = endpoint + `/getSocialProfileX?access_token=${token.access_token}`;
        break;

      default:
        console.error(`(getUserSocialProfile_service) - ${Auth0ProviderResponseType.UNKNOWN_PROVIDER} :` + provider);
        return null;
    }

    // Call our exchangeAuthorizationCode serverless API endpoint, with our authorization code
    const response = await fetch(fetchRequest);
    const data = await response.json();

    switch (response.status) {
      case 200: // Success case
        // Parse the response as JSON, and return the User object
        // Expected return format: { sub: string, name: string, email: string, profile_pic: string }
        const parsedUser = User.fromSocialAPI_JSON(data, provider);
        return parsedUser;

      default:
        console.log("(getUserSocialProfile) - An unexpected return code was encountered in getUserSocialProfile serverless call");
        throw new Error(MONGO_DB_RESPONSE_TYPES.INTERNAL_SERVER_ERROR);
    }
  }
  catch (error) 
  {
    console.error("(getUserData) - An unexpected error was encountered in getUserData_service.ts: " + error);
    throw new Error(Auth0ProviderResponseType.USERDATA_FETCH_ERROR);
  }
}