import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoClose } from "react-icons/io5";
import { FcGoogle } from "react-icons/fc";
import { useAuth0 } from "@auth0/auth0-react";

import  { WINDOW_MODE } from './library/WindowInterface';
import { Colors, RouteType, SocialAuthType } from "./data types/mystarpath_types";
import PopupInterface from "./library/PopupInterface";

type LoginPageProps = {
    animationFinished: () => void;
};

/*
 * LoginPage.tsx
 * 
 * Behavior of this component, while NOT in the home page::
 * Regular Window:
 * 
 * Halved Window:
 * 
 * Mobile Window:
 * 
 * Landscaspe Window:
*/
export default function LoginPage({animationFinished} : LoginPageProps) {

    console.log("(LoginPage) - Rendering!");

    // #region React Hooks
    
    const navigate = useNavigate(); // Hook to navigate to different pages
    const { loginWithRedirect } = useAuth0();
    const [forceCloseLoginInterface, setForceCloseLoginInterface] = useState<boolean>(false); // State variable to force close the login interface
    const [closeButtonHover, setCloseButtonHover] = useState<boolean>(false); // Controls the hover effect of the close button
    const [email, setEmail] = useState<string>(''); // State variable to store the user's email

    // #endregion

    // #region UI Helpers

    const getPanelPositioning = (_m: WINDOW_MODE) : string => {

        switch(_m) {
            case WINDOW_MODE.NORMAL:
            case WINDOW_MODE.HALVED:
                return 'left-1/2 top-1/2 '; //Positions the left edge of the element at 50% of the container's width, and translates the element left by 50% of its own width, effectively centering it horizontally.

            case WINDOW_MODE.MOBILE:
                return 'w-full h-full mt-5 mx-3 ';
        }
    }

    const getExpandedWidth = (_m: WINDOW_MODE, w: number) => { //TODO AARON - Change to a variable, that only recalculates when the window size changes! 

        switch(_m) {
            case WINDOW_MODE.NORMAL:
            case WINDOW_MODE.HALVED:
                return 575; //w * 0.30;
        }
    };

    const getExpandedHeight = (_m: WINDOW_MODE, h: number) => {

        switch(_m) {
            case WINDOW_MODE.NORMAL:
            case WINDOW_MODE.HALVED:
                return h * 0.60;
        }
    };

    const userNextButtonClicked = (username: string) => {

        // Pass along the email as a login hint, and specify the database connection
        loginWithRedirect({ authorizationParams: {
            connection: "Username-Password-Authentication",
            login_hint: email,
            screen_hint: "login"    
        }});
    }

    // #endregion

    // #region Tailwind Styles

    const tooltipStyle = 'absolute bottom-full left-1/2 transform -translate-x-1/2 ' + // Tooltip for the exit button 
     'font-liberation-mono ' + // Font style
     'px-2.5 text-sm text-white bg-gray-700 rounded opacity-0 transition-opacity ' +
     `${closeButtonHover ? 'delay-500 opacity-100' : 'opacity-0'}`; // Immediate close on mouse-exit (state variable); delayed open on mouse-enter 

    const exitButtonStyle = 'absolute top-0 left-0 m-1.5 h-8 w-8 flex items-center justify-center cursor-pointer'; // Absolutely positioned at the top-left of the panel

    const exitButtonHoverStyle = 'absolute inset-0 bg-gray-500 rounded-full flex items-center justify-center ' + // Absolutely positioned at the top-left of the panel
        'opacity-0 transition-opacity duration-300 hover:opacity-20'; // Hover effect for the button

    const loginTitleContainerStyle = 'flex flex-col items-center w-full h-full mt-6 bg-transparent ';

    const socialButtonStyle = 'w-1/2 h-10 items-center justify-center mt-10 ' + // Social button size and positioning
        'rounded-lg bg-white ' + // Rounded corners, border, and text color
        'cursor-pointer select-none transition-colors duration-300 hover:bg-dull-white'; // Hover effect for the socials buttons

    const socialButtonStyle_Transparent = 'w-1/2 h-10 items-center justify-center mt-10 ' + // Social button size and positioning
    'text-dull-white rounded-lg border border-gray-400 bg-transparent ' + // Rounded corners, border, and text color
    'cursor-pointer select-none transition-colors duration-300 hover:bg-gray-400 hover:bg-opacity-10'; // Hover effect for the socials buttons

    const textIntputStyle = 'h-12 w-1/2 px-4 py-2 ' + // Text input field for the username/email
        'text-gray-400 bg-transparent border border-gray-400 rounded-sm outline-none ' +
        'font-liberation-mono ' + // Font styl
        'focus:border-neon-purple focus:ring-1 focus:ring-neon-purple'; 


    // #endregion

    //#region JSX Return

    return (

        <PopupInterface getInterfacePosition={getPanelPositioning}
                        getExpandedWidth={getExpandedWidth}
                        getExpandedHeight={getExpandedHeight}
                        closeInterface={forceCloseLoginInterface}
                        animationFinished={animationFinished}>
            
                <div id="Login-exit-group" className="relative group">

                    <button id="Exit-Login-Button"
                            className={exitButtonStyle}
                            onMouseEnter={() => setCloseButtonHover(true)}
                            onMouseLeave={() => setCloseButtonHover(false)}
                            onClick={() => { setForceCloseLoginInterface(true); } }> { /* Triggers the closing animation on the PopupInterface parent component */ }
                        
                        <IoClose color={Colors.DULL_WHITE} size={24} /> {/* 'X' icon '*/ }

                        <div className={exitButtonHoverStyle} /> {/* Rounded background, for exit button */ }

                        <div className={tooltipStyle}> {/* Tooltip, for exit button */ }
                            Close
                        </div>

                    </button>

                </div>

                <div className={loginTitleContainerStyle}>
                    <h1 className="text-4xl font-nidus-regular tracking-wider text-dull-white pointer-events-none select-none">Sign in</h1>

                    <button className={socialButtonStyle} 
                            style={{marginTop: '3.0em'}}
                            onClick={ () => navigate(RouteType.OAUTH_REQUEST_PAGE, {state: {provider: SocialAuthType.GOOGLE} }) }> {/* Google Sign-in Button */}

                        <div className="flex w-full h-full items-center justify-center">
                            <FcGoogle className="mr-1.5" size={20}/> Sign in with Google
                        </div>

                    </button>

                    <div className="flex w-1/2 items-center my-4"> {/* 'or' Divider Line */ }

                        <div className="flex-grow border-t border-gray-400"></div>                  
                            <span className="mx-3 text-dull-white font-bold font-liberation-mono">OR</span>
                        <div className="flex-grow border-t border-gray-400"></div>
                        
                    </div>

                    <input type="text" 
                           placeholder="Email or Username"
                           onChange={(e) => setEmail(e.target.value)}
                           className={textIntputStyle} /> {/* Manually enter Email or username input box */}

                    <button className={socialButtonStyle} 
                            style={{marginTop: '1.25em'}}
                            onClick={ () => userNextButtonClicked(email) }> {/* Manual sign in "Next" Button */}

                    <div className="flex w-full h-full font-bold items-center justify-center">
                         Next
                    </div>

                    </button>

                    <button className={socialButtonStyle_Transparent} style={{marginTop: '0.75rem'}}> {/* Forgot password? Button */}

                        <div className="flex w-full h-full items-center justify-center">
                            Forgot password?
                        </div>

                    </button>

                    <div className="w-1/2 mt-6 text-right text-gray-400">
                        Don't have an account? <a href="#" className="font-bold font-liberation-mono text-neon-purple">[Sign up]</a>
                    </div>

                </div>

        </PopupInterface>
    );

    // #endregion

}

/*
                    <button className={socialButtonStyle}
                            onClick={() => navigate(RouteType.OAUTH_REQUEST_PAGE, {state: {provider: SocialAuthType.TWITTER} }) }> 

                            <div className="flex w-full h-full items-center justify-center">
                            <BsTwitterX className="mr-1.5" size={20}/> Sign in with X.com
                        </div>

                    </button>
                         */