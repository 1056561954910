import { MONGO_DB_RESPONSE_TYPES } from "../data types/mystarpath_types";

export default async function CheckUsername(name: string, closeConnection: boolean) : Promise<boolean> {

    const isInProduction = import.meta.env.MODE === 'production';
    const endpoint = (isInProduction) ? import.meta.env.VITE_API_PROD_ENDPOINT : 
                                        import.meta.env.VITE_API_DEV_ENDPOINT;

    try 
    {
        console.log(`(checkUsername_service) - CloseConnection: ${closeConnection}`);
        // Call our checkUsername API endpoint, with the username and parameter to close the persistent db connection, or not
        const response = await fetch(endpoint + `/check-username?username=${name}&closePersistentDBConnection=${closeConnection}`);

        // Wait for the server's response
        const data = await response.json();

        switch(response.status) {

            case 200: // Success case
                return data.isAvailable;

            case 400:
                throw new Error(MONGO_DB_RESPONSE_TYPES.MISSING_REQUEST_PARAMETER);

            case 500:
                throw new Error(MONGO_DB_RESPONSE_TYPES.INTERNAL_SERVER_ERROR);

            default: 
                throw new Error(MONGO_DB_RESPONSE_TYPES.UNEXPECTED_ERROR);
        }
      } 
      catch (error) { // Throw the error back to the caller
        console.error("(checkUsername_service) - Unexpected error while checking username availability: " + error);
        throw error; 
      }
};