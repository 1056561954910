// #region Types and Imports

//import { Location, useLocation } from "react-router-dom";
import React, { useEffect, useCallback, useMemo, useImperativeHandle, useState, useRef, forwardRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Howler from 'howler';

import './style.css'
import useWindowSize from './library/WindowInterface';
import { SceneType, RouteType, SceneInterface, SceneName, User } from './data types/mystarpath_types';
import UserManagement, { UserManagementInterface } from './data_providers/UserManagement';
import TextureProvider, { TextureProviderInstance } from './data_providers/TextureProvider';
import ErrorScene from './scenes/Error_Encountered/ErrorScene';
import HyperspaceScene from './scenes/Hyperspace/HyperspaceScene';
import GalaxyScene, { GalaxySceneInterface, GalaxySceneActionItems } from './scenes/Galaxy/GalaxyScene';
import { useLocation, Location } from 'react-router-dom';
import OauthCallbackPage, { OAuthLoginRequest } from './library/OAuthHelpers';
import { Handler } from "@netlify/functions"; // Provides TypeScript types for Netlify Functions
import CreateAccountScene from './scenes/Create_Account/CreateAccountScene';
// #endregion

// Disable the auto-unlock feature for Howler.js
Howler.autoUnlock = false; 

const SceneManager = () => 
{
    console.log("(SceneManager) - Now Rendering the SceneManager!");

    // #region State Variables

    const routeLocation = useLocation();
    const { loginWithRedirect } = useAuth0();
    const [managerBackground, setManagerBackground] = useState<string>("bg-background-grey"); // Background color of the scene manager
    const [currentScene, setCurrentScene] = useState<SceneType>({ sceneName: SceneName.NONE, 
                                                                  page: null }); // State variable to determine the current scene
    const { width, height } = useWindowSize(); // Hook to get the window size
    
    // #endregion

    // #region Memos and References

    const textureProvider = useMemo(() => new TextureProvider(), []); // Used to load and grab textures for the active scene
    const pageRef = useRef<SceneInterface>(null); // Reference to the current page
    const GalaxyPage_ref = useRef<GalaxySceneInterface>(null); // Reference to the Galaxy Scene

    // #endregion

    // #region Helper Methods

    function goHome(metadata?: any) 
    {
        switch(currentScene.sceneName) {

            case SceneName.CREATE_ACCOUNT_SCENE:                
                console.log("(SceneManager) - Navigating back to the Home screen, from the Create Account scene!");

                setCurrentScene({
                    sceneName: SceneName.GALAXY_SCENE,
                    page: <GalaxyScene ref={GalaxyPage_ref} />
                });

            break;

            case SceneName.NONE:
                console.log("(SceneManager) - Inserting Home Scene, from a Default State!");

                setCurrentScene({
                    sceneName: SceneName.GALAXY_SCENE,
                    page: <GalaxyScene ref={GalaxyPage_ref} />
                });

                break;

            case SceneName.GALAXY_SCENE:
                
                if(GalaxyPage_ref.current) {
                    GalaxyPage_ref.current.goHome();
                }
                else {
                    console.warn("(SceneManager) - Warning - Galaxy Scene reference wasn't expected to be null.");
                    // Set the scene back to NONE, to refresh the Galaxy Scene reference
                    // TODO Aaron! - Test this logic
                    setCurrentScene({
                        sceneName: SceneName.NONE,
                        page: null
                    });
                }            

                break;

            case SceneName.OAUTH_CALLBACK_SCENE:
                console.log("(SceneManager) - Navigating back to the Home screen, from an OAuth Callback context!");

                console.log("(SceneManager) - DEBUG TESTING! Metadata: " + metadata);

                if(!metadata || !metadata.action) { // TODO Aaron! - Test logic 
                    console.error("(SceneManager) - Metadata is missing the action to perform after the OAuth callback!");

                    // Reset the state to the home screen
                    setCurrentScene({
                        sceneName: SceneName.NONE,
                        page: null
                    });    
                }

                const action = metadata.action as GalaxySceneActionItems;

                if(GalaxyPage_ref.current) {
                    GalaxyPage_ref.current.goHome(action);
                }
                else {
                    console.warn("(SceneManager) - Warning - Galaxy Scene reference wasn't expected to be null.");

                    setCurrentScene({ sceneName: SceneName.GALAXY_SCENE,
                                      page: <GalaxyScene ref={GalaxyPage_ref} actions={action} />
                    });
                }

                break;

            default:
                console.error("(SceneManager) - Current scene (" + currentScene.sceneName + ") does not support opening the home page directly!");
        }
    }   

    function loginPage()
    {
        switch (currentScene.sceneName) {

            case SceneName.NONE: // This is the case where the user navigates directly to the login page (and not from a button link)

                console.log("(SceneManager) - Inserting Login Scene, from a Default State!");

                setCurrentScene({
                    sceneName: SceneName.GALAXY_SCENE,
                    page: <GalaxyScene ref={GalaxyPage_ref} actions={GalaxySceneActionItems.OPEN_LOGIN_PAGE} />
                });

                break;
                
            case SceneName.GALAXY_SCENE:

                console.log("(SceneManager) - Signaling the Galaxy Scene to open the Login Page!");
                    GalaxyPage_ref.current.openLoginPage();
                break;

            default:
                console.error("(SceneManager) - Current scene (" + currentScene.sceneName + ") does not support opening the login page directly!");
        }
    }

    function createAccountPage(metadata: any)
    {
        const _user = new User(metadata.user.auth_type, 
            metadata.user.auth0_sub, 
            metadata.user.username, 
            metadata.user.email, 
            metadata.user.name, 
            metadata.user.picture); // Create a new user object from the metadata

        const access_token = metadata.access_token;

        setCurrentScene({
            sceneName: SceneName.CREATE_ACCOUNT_SCENE,
            page: <CreateAccountScene user={ _user } 
                                      access_token={ access_token } />
        });
    }

    function errorScene(metadata: any)
    {
        // Check that metadata is of the correct type
        if(!metadata || !metadata.statusText || !metadata.message) {
            console.error("An error was encountered, but the metadata was missing or incorrect!");
            metadata = { statusText: "500", message: "An unexpected error was encountered!" };
        }

        console.log("(SceneManager) - An error was encountered! Status: " + metadata.statusText + " Message: " + metadata.message);
        console.log("(SceneManager) - Switching to the Error Scene!");

        // Switch to the error scene, sending the current scene as metadata
        setCurrentScene({ sceneName: SceneName.ERROR_SCENE,
                          page: <ErrorScene error={{ statusText: metadata.statusText, 
                                                     message: metadata.message }}
                                            previousScene={ currentScene.sceneName }  />
        });
    }

    function handleRouteChange(location: Location, metadata: any)
    {
        switch (location.pathname) {

            case RouteType.CREATE_ACCOUNT_PAGE:
                console.log("(SceneManager) - Switching to the Create Account Page!");
                createAccountPage(metadata); 

                break;

            case RouteType.ERROR_PAGE: // Metadata should be of tyoe RouteErrorType
                errorScene(metadata); // Signal the SceneManager to switch to the error scene

                break;

            case RouteType.HOME_PAGE:      
                console.log("(SceneManager) - Switching to the Home Page!");
                goHome(metadata); // Signal the SceneManager to switch to the home page

            break;

            case RouteType.LOGIN_PAGE:
                console.log("(SceneManager) - Signaling to the current scene to open the Login Page!");
                loginPage(); // Signal the SceneManager to switch to the login page

                break;

            case RouteType.OAUTH_REQUEST_PAGE:
                console.log("(SceneManager) - Handling the request for OAuth Social Sign-in!");
                OAuthLoginRequest(metadata); // Log in with the specified provider

                break;

            case RouteType.OAUTH_CALLBACK_PAGE:
                console.log("(SceneManager) - Switching to the OAuth Callback Scene!");
                setCurrentScene({
                    sceneName: SceneName.OAUTH_CALLBACK_SCENE,
                    page: <OauthCallbackPage location={location} />
                });

                break;

            default:
                console.error("Unexpected error: route not found! " + location.pathname);
        }
    }

    // #endregion
        
    // #region React Effects

    //Effect that runs on first mount. Currently being used purely for logging purposes
    useEffect(() => {
        console.log("(SceneManager) - Mounted!")

        return () => {console.log("(SceneManager) - Unmounted!")}
    }, []); 

    // Effect that triggers whenever the browser route changes
    useEffect(() => {

        console.log("(SceneManager) - Route has changed! New path: " + routeLocation.pathname);
        handleRouteChange(routeLocation, routeLocation.state); // Handle the new route

    }, [routeLocation]);

    // #endregion

    // #region JSX Return

    return (

        <UserManagement>
            <TextureProviderInstance.Provider value={textureProvider}>

                <div id="scene-holder-div" className={`absolute w-full h-full ${managerBackground}`}>
                    {currentScene.page}
                </div>

            </TextureProviderInstance.Provider>
        </UserManagement>
                         
    );

    // #endregion

};

export default SceneManager;
