// #region Types and Imports

import React, { useContext, useState } from 'react'
import { useSpring, animated, useChain, useSpringRef } from '@react-spring/web';
import { useNavigate } from 'react-router-dom';
import { ImSpinner7 } from "react-icons/im";

import { UserManagementContext } from '../../data_providers/UserManagement';
import { addUser } from '../../services/addUser_service';
import ChangeUsername from '../../library/ChangeUsername';
import { RouteType, User } from '../../data types/mystarpath_types'

export type CreateAccountSceneProps = {

    user: User,
    access_token: string
};

export default function CreateAccountScene({ user, access_token }: CreateAccountSceneProps)
{
    // #region Variables

    const navigate = useNavigate(); // Hook to navigate to different pages
    const userManagement = useContext(UserManagementContext); // Reference to the UserManagement context
    const [termsOfServiceAccepted, setTermsOfServiceAccepted] = useState<boolean>(false); // State variable to determine if the user has accepted the terms of service
    const [pageProcessing, setPageProcessing] = useState<boolean>(false); // State variable to determine if we're processing a request to the database

    // #endregion

    // #region Animations

    // References for the animations, so we can use useChain to string them together
    const TOSFadeRef = useSpringRef();
    const createUsernameFadeRef = useSpringRef();

    const TOSFadeAnimation = useSpring({
        ref: TOSFadeRef,
        opacity: termsOfServiceAccepted ? 0 : 1,
        config: { duration: 250 },
    });

    const createUsernameFadeAnimation = useSpring({
        ref: createUsernameFadeRef,
        opacity: termsOfServiceAccepted ? 1 : 0,
        config: { duration: 250 },
    });

    // Chain the animations together
    useChain(termsOfServiceAccepted ? [TOSFadeRef, createUsernameFadeRef] : [createUsernameFadeRef, TOSFadeRef], [0, 0.3]);

    // #endregion

    // #region Helper Methods

    const AddUser = async (username: string) => {

        console.log(`(CreateAccountScene) - Awaiting new user addition to our database, with username: ${username}`);

        // Notify component that we're now loading!
        setPageProcessing(true);

        try {
            // Add the new username to the user's object
            user.changeUsername(username);

            console.log(`(CreateAccountScene) - User object updated with new username: ${username}`);
            console.log(user);

            //Call our add user service
            const newDatabaseUser = await addUser(user);

            console.log("(CreateAccountScene) - User added successfully to the database!");
            console.log(newDatabaseUser);

            // Save our newly created user to the UserManagement 
            userManagement.setAuthenticatedUser(newDatabaseUser);

            // Navigate to the home page
            navigate(RouteType.HOME_PAGE);
        }
        catch(error) 
        {
            // Handle error type MONGO_DB_RESPONSE_TYPES.USER_GET_FAILED, here!
            // TODO, Aaron! - Navigate to the error page
            console.log("(CreateAccountScene) - TODO! Need to navigate error, to the dedicated error page!");
        }
    };

    // #endregion

    // #region JSX Return

    return (

        <div className='absolute w-full h-full flex flex-col items-center justify-center bg-background-grey'>

            {pageProcessing && <ImSpinner7 size={72} className='animate-spin text-neon-purple' />}

            { /* Show terms of service page, before username creation */ }
            { !pageProcessing &&

                <animated.div id='create-account-terms-div'
                              style={ TOSFadeAnimation }>
                    
                    <h1 className='text-dull-white mb-5'>Create Account Scene</h1>

                    <div>
                        <button id='decline-terms-button'
                                className='bg-purple-base text-dull-white font-bold mx-5'
                                onClick={ () => navigate(RouteType.HOME_PAGE) } >
                            Decline
                        </button>

                        <button id='accept-terms-button'
                                className='bg-purple-base text-dull-white font-bold mx-5'
                                onClick={ () => setTermsOfServiceAccepted(true) }>
                            Accept
                        </button>
                    </div>

                </animated.div> 
            } 

            { /* Show username creation page, after terms of service acceptance */ }
            { !pageProcessing &&

                <animated.div className='relative w-full h-full bg-background-grey'
                              style={createUsernameFadeAnimation}>

                    <ChangeUsername goBack={ () => setTermsOfServiceAccepted(false) }
                                    createNewUser={AddUser} />

                </animated.div>
            }

        </div>
    )

    // #endregion
    
}