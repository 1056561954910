import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Location } from "react-router-dom";
import { ImSpinner7 } from "react-icons/im";

import { generateRandomState, generateCodeChallenge, generatePlainCodeVerifier, generateCodeVerifier } from "../security/SecurityGeneration";
import { UserManagementContext, UserManagementInterface } from "../data_providers/UserManagement";
import { exchangeAuthorizationCode } from "../services/exchangeAuthorizationCode_service";
import { GalaxySceneActionItems } from "../scenes/Galaxy/GalaxyScene";
import { Auth0ProviderResponseType, RouteType, SocialAuthType, User } from "../data types/mystarpath_types";
import { getUser } from "../services/getUser_service";
import { getUserSocialProfile } from "../services/getUserSocialProfile_service";
import { RouteErrorType } from "../scenes/Error_Encountered/ErrorScene";

export default function OauthCallbackPage({ location}: { location: Location })
{
  // #region Variables

  const UserManagementProvider = useContext<UserManagementInterface>(UserManagementContext); // The user management provider context
  const navigate = useNavigate(); // Hook to navigate to different pages
  const [userData, setUserData] = useState<{user: User, access_token: string}>(null); // State variable to store the user data, and their corresponding access_token from the social media provider
  const [success, setSuccess] = useState<boolean>(false); // State variable to determine if we successfully contacted the database for the user's data
  const [error, setError] = useState<string | null>(null); // State variable to determine if an error has occurred

  // #endregion

// #region React Effects

// Effect that triggers, on first mount
// Used to fire off the token exchange process
  useEffect(() => {

    const { code, state } = retrieveCodeStateFromURL(location); // Extract the code and provider from the URL
    const { error, socialProvider } = checkForValidProvider(); // Check if the provider is valid

    if(error) {
      setError(error); 
      return;
    }

    retrieveAPIAuthorizationCode(code, state, socialProvider)
      .then((data) => {

        console.log("(OauthCallbackPage) - Token exchange completed successfully!");

        getUserSocialProfile(data, socialProvider) // Fetch the user data from the provider

          .then((user_data) => {
            console.log("(OauthCallbackPage) - User data fetched successfully!");
                       
            setUserData({user: user_data, access_token: data.access_token}); // Save the user's data to the component
          })     
          .catch( () => setError(Auth0ProviderResponseType.USERDATA_FETCH_ERROR))

      })
      .catch((error) => {
        console.error("(OauthCallbackPage) - Error exchanging the authorization code for an access token!")
        setError(Auth0ProviderResponseType.RETRIEVE_EXCHANGE_CODE_ERROR);
      })

  }, []);

  // Effect that triggers when the user's tokens are fetched from the social login provider callback
  useEffect(() => {

    // Only proceed if we have the user's tokens
    if(!userData) {
      return;
    }

    // Does the user already exist?
    getUser(userData.user)
      .then((user : User) => {

        if(user == null) {
          console.log("(OAuthHelper) - User does not exist in the database!");
          
          // Move on to prompt the user to create an account!!
          setSuccess(true);

          navigate(RouteType.CREATE_ACCOUNT_PAGE, // Redirect the user to the create account page
                  { state: { user: userData.user, access_token: userData.access_token }
          });

        }
        else {
          console.log("(OAuthHelper) - User found in the database - logging them in!!");
          console.log(user);

          UserManagementProvider.setAuthenticatedUser(user); // Save the user as the authenticated user 
          
          setSuccess(true); // Set the success state to true
          
          navigate(RouteType.HOME_PAGE, //Redirect the user back to the home page
                  { state: { action: GalaxySceneActionItems.SHOW_LOGIN_SUCCESS_MESSAGE            
          }}); 

        } 
      })
      .catch((error) => {
        console.log("(OAuthHelper) - TESTING!!! " + error);
        setError(error.message);
      });

  }, [userData]);

  // Effect that triggers when an error is set
  useEffect(() => {

    if(error) {

      const newError: RouteErrorType = {
        statusText: "An error was encountered, while logging in!",
        message: error
      };

      // Redirect the user to the error page
      console.log("(OAuthCallback) - Navigating to the error page!");
      setTimeout(() => {
          navigate(RouteType.ERROR_PAGE, { state: { statusText: newError.statusText,
                                                  message: newError.message
                                                  
        }}); 
      }, 1000);
    }

  }, [error]);

// #endregion

    return (

      <div id="oauth-callback-page"
           className="absolute w-full h-full flex items-center justify-center bg-background-grey">

           <ImSpinner7 size={72} className="animate-spin text-neon-purple" />

      </div>
    );
};


// #region Helper Methods

export async function OAuthLoginRequest(metadata: any)
{
  // Perform a basic parameter check before moving on
  if(!metadata || !metadata.provider) {
    console.error("(SceneManager) - 'Provider' Metadata is missing for the OAuth Request Page!");
    return;
  }

  const oauth_provider = metadata.provider as SocialAuthType;
  const isInProduction = import.meta.env.MODE === 'production';

  const secure_state = generateRandomState(); // Generate a secure state string
  const codeVerifier = await generatePlainCodeVerifier(); // TODO! : Implement PKCE

  // Save the state and codeVerifier to the session storage, so we can use it after redirect callback
  sessionStorage.setItem("oauth_state", secure_state); 
  sessionStorage.setItem('pkce_code_verifier', codeVerifier);

  switch(oauth_provider) {

    case SocialAuthType.GOOGLE:

      try 
      {
        console.log("(OAuthHelpers.tsx) - Redirecting to Google OAuth2 Login Page!");

        // Create the query parameters for the Google OAuth2 request
        const params = new URLSearchParams({
            client_id: import.meta.env.VITE_AUTH0_GOOGLE_CLIENT_ID,
            redirect_uri: (isInProduction) ? import.meta.env.VITE_AUTH0_REDIRECT_PRODUCTION_URI : import.meta.env.VITE_AUTH0_REDIRECT_DEV_URI,
            response_type: "code", // Authorization Code Flow
            scope: "openid profile email", // Requesting the user's profile and email
            state: secure_state, // CSRF protection
        });

        // save the provider for later usage
        sessionStorage.setItem("oauth_provider", SocialAuthType.GOOGLE);

        // Redirect the user to the Google OAuth2 page
        window.location.href = import.meta.env.VITE_AUTH0_GOOGLE_LINK + "?" + params.toString();
      }  
      catch (error) {
          console.error("(LoginPage) - Error logging in with the provider: " + oauth_provider);
          console.error(error);
      }
      break;

    case SocialAuthType.TWITTER:
        console.log("(OAuthHelpers.tsx) - Redirecting to Twitter OAuth2 Login Page!");

        // Create the query parameters for the X.com OAuth2 request
        const params = new URLSearchParams({
          client_id: import.meta.env.VITE_AUTH0_TWITTER_CLIENT_ID,
          redirect_uri: (isInProduction) ? import.meta.env.VITE_AUTH0_REDIRECT_PRODUCTION_URI : import.meta.env.VITE_AUTH0_REDIRECT_DEV_URI,
          response_type: "code", // Authorization Code Flow
          scope: "users.read tweet.read offline.access", // Requesting the user's profile and email. offline.access allows us to get a "refresh token.""
          state: secure_state, // CSRF protection
          code_challenge: codeVerifier, // PKCE (todo!)
          code_challenge_method: "plain" // PKCE (todo!)
      });

        // save the provider for later usage
        sessionStorage.setItem("oauth_provider", SocialAuthType.TWITTER);

        // Redirect the user to the X.com's OAuth2 page
        window.location.href = import.meta.env.VITE_AUTH0_TWITTER_LINK + "?" + params.toString();

      break;

    default:
      console.error("(OAuthLoginRequest) - Unknown provider: " + oauth_provider);
      break;
  }
}

/**
 * Checks for a valid social authentication provider stored in session storage.
 * 
 * @returns An object containing:
 * - `error`: A string representing an error message if the provider is unknown, otherwise `null`.
 * - `socialProvider`: The valid social authentication provider if found, otherwise `null`.
 */
function checkForValidProvider() : {error: string, socialProvider: SocialAuthType}
{
  let error = null;
  let socialProvider = null;

  // Retrieve the provider from session storage
  const provider = sessionStorage.getItem("oauth_provider");

  switch(provider) {
    case SocialAuthType.GOOGLE:
      socialProvider = SocialAuthType.GOOGLE;
      break;

    case SocialAuthType.TWITTER:
      socialProvider = SocialAuthType.TWITTER
      break;

    default:
      console.error("(OAuthCallback) - Unknown provider: " + provider);
      error = Auth0ProviderResponseType.UNKNOWN_PROVIDER;
      break;
  }

  return {error, socialProvider};
}

// TODO Aaron - Document!
function retrieveCodeStateFromURL(location: Location) : {code: string | null, state: string | null}
{
    const params = new URLSearchParams(location.search); // Get the query parameters from the URL
    const state = params.get('state');
    const code = params.get('code');

    console.log("DEBUG: " + state + " | " + code);

    return {code, state};
}

/**
 * Handles the OAuth callback by extracting the authorization code and provider from the URL,
 * and then exchanges the authorization code for an access token.
 *
 * @param {Location} location - The location object containing the URL with query parameters.
 * @param {any} metadata - Additional metadata that might be needed for the callback.
 * @returns {Promise<{access_token: string; id_token?: string}>} - A promise that resolves to an object containing the access token and optionally an ID token.
 *
 * @throws Will log an error if the authorization code or provider is missing from the URL.
 */
async function retrieveAPIAuthorizationCode(code: string, state: string, provider: SocialAuthType ) : Promise<{access_token: string; id_token?: string}>
{
  if(!code || code == "" ) {
    console.error("(OAuthHelpers, retrieveAPIAuthorizationCode) - Authorization code is missing!");
    throw new Error("Missing Authorization code!");
  }

  if(!checkForValidState(state)) {
    console.error("(OAuthHelpers, retrieveAPIAuthorizationCode) - Invalid state detected!");
    throw new Error("Invalid state detected!");
  }

  // Retrieve the code_verifier, previously stored in sessionStorage
  const codeVerifier = sessionStorage.getItem('pkce_code_verifier');

  // Exchange the authorization code for an access token
  return await exchangeAuthorizationCode(code, provider, codeVerifier);
}

/**
 * Checks if the provided state matches the state stored in session storage.
 *
 * @param state - The state string to validate.
 * @returns `true` if the provided state matches the state in session storage, otherwise `false`.
 */
function checkForValidState(state: string) : boolean {

  console.log("(OAuthCallback) - Checking for a valid state, from session storage!");

  const session_state = sessionStorage.getItem("oauth_state");

  if(state != session_state) {
    console.log("(OAuthCallback) - Invalid state detected!");
    console.log("(OAuthCallback) - Expected: " + session_state + "| Received: " + state);
    return false;
  }

  return true;
}

// #endregion