import { a } from "@react-spring/web";
import { redirect } from "react-router-dom";
import { Texture } from "three";

// #region Animations

export enum AnimationType  {

    NONE,
    ENTER_SCENE_ANIMATION,
    NAVIGATION_WINDOW_CLOSED,
    PRE_BURST,
    BURST,
    SHAKE_SCREEN,
    BOOST,
    FINAL_BURST,
    DECELERATE_SPACESHIP_ANIMATION,
    DECELERATE_HYPERSPACE_ANIMATION,
    EXIT_HYPERSPACE_ANIMATION,
    HUD_TEXT_ENDED,

};

export enum AnimationType_GalaxyScene {
    
    NONE,

}

export interface AnimationProps {

    INTRO_SCREEN_ANIMATION_BEGIN?: {
        startingLookPos: vec3;
    }

    INTRO_SCREEN_ANIMATION_ENDED?: {
        lookAtPos: vec3;
    };

    BURST_ENDED?: {
        setRenderPlanets: React.Dispatch<React.SetStateAction<boolean>>;
    };
}

export enum ExpansionContractionAnimationProps {
    
        EXPAND,
        CONTRACT,
}

export interface PulseAnimationProps {

    blur: string,
    brightness: string,
}

// #endregion

// #region Audio

export enum AudioFile {

    HUD_TEXT_BEEP = '/assets/sounds/Interface_Typing.mp3',
    HYPERSPACE_ANIMATION_SOUND = '/assets/sounds/hyperspace-animation-sound.mp3',
    SUBMIT_SOUND = '/assets/sounds/submit-sound.mp3',
}

export type AudioCacheType = {
    
    audioElement: HTMLAudioElement;
    componentsInUse: string[];
    onPlayCallbacks: (() => void)[];
}

export interface AudioControllerContextType {
    
    preloadAudio: (src: AudioFile, caller: string, onPlay?: () => void) => Promise<HTMLAudioElement>;
    playAudio: (src: AudioFile) => void;
}

// #endregion

// #region Data Types and Functions

export class PlanetType {   

    constructor(planet_id : string, position : [number, number, number], dayTexture : TextureType, nightTexture : TextureType,
                color : string, atmosphereColor? : string, cloudTexture?: TextureType, isVisible?: boolean) {

        this.planetID = planet_id;
        this.planetPosition = position;
        this.planetDayTexture = dayTexture;
        this.planetNightTexture = nightTexture;
        this.planetCloudTexture = cloudTexture;
        this.planetColor = color;
        this.planetAtmosphereColor = atmosphereColor ?? color;
        this.isVisible = isVisible ?? false;
    }

    public readonly planetID : string;
    public planetPosition : vec3;
    public planetDayTexture : TextureType;
    public planetNightTexture : TextureType
    public planetCloudTexture : TextureType;
    public planetColor : string;
    public planetAtmosphereColor : string;
    
    public isVisible: boolean;

};

export enum TextureType {

    EARTH_BRIGHT,
    EARTH_DARK,
    EARTH_SPECULAR_CLOUDS,
    LENS_FLARE,
    XERXES,
};

export type vec3 = [number, number, number];
export function vec3_add(a: vec3, b: vec3): vec3
{
    return [
        a[0] + b[0], 
        a[1] + b[1], 
        a[2] + b[2]
    ];
}




// #endregion

// #region Colors

export enum Colors {
    PURPLE_BACKGROUND = '#1e122c',
    DARK_PURPLE_BACKGROUND = '#08030e',
    PURPLE_BASE = '#491e80',
    NEON_PURPLE = '#b172fa', 
    FADED_NEON = '#e6cffb',
    SELECTION_YELLOW = '#eec10d',
    DULL_WHITE = '#e7e7e8',
    ERROR_RED = '#f30000',
    SPACE_BACKGROUND = '#000000',
    UX_GRAY = '#0e0f0f',
}

// #endregion

// #region Tailwind Styles

export enum ButtonStyles {
    
    // py-2: padding on the y-axis of 2 units
    // bg-transparent: background color is transparent
    // text-neon-purple: text color is neon purple
    // select-none: text selection is disabled
    // focus:outline-none: outline is removed when the button is focused
    bare = "py-2 bg-transparent text-neon-purple select-none focus:outline-none",
};

// #endregion

// #region Scenes

export enum SceneName {

    NONE,
    CREATE_ACCOUNT_SCENE,
    ERROR_SCENE,
    HYPERSPACE_SCENE,
    GALAXY_SCENE,
    OAUTH_CALLBACK_SCENE,
};

export type SceneType = {
    
    sceneName: SceneName;
    page: JSX.Element;
}

export interface SceneInterface {
    sceneAction: (SceneActionType) => void;
}

// #endregion

// #region Website Routes

export enum RouteType {

    CREATE_ACCOUNT_PATH = "create",
    CREATE_ACCOUNT_PAGE = "/create",
    ERROR_PATH = "error_encountered",
    ERROR_PAGE = "/error_encountered",
    HOME_PAGE = "/",
    LOGIN_PATH = "login",
    LOGIN_PAGE = "/login",
    OAUTH_REQUEST_PATH = "request",
    OAUTH_REQUEST_PAGE = "/login/request",
    OAUTH_CALLBACK_PATH = "callback",
    OAUTH_CALLBACK_PAGE = "/login/callback",
};

// #endregion

// #region Components

export type PlanetContextType = {
    selectedPlanet: PlanetType; //The ID of the planet that is currently selected in the scene
    zoomedPlanet: PlanetType; // The ID of the planet that is currently zoomed in on, in the scene
    scene: SceneInterface; // Add this line to use the renamed interface
};

export type PlanetPlaceholderProps = {
    position: vec3;
    planetColor: string;
}

export type PlanetProps = {

    planetID: string;
    planetPosition: [number, number, number];
    planetDayTexture: Texture; 
    planetNightTexture: Texture;
    planetColor: string;
    planetAtmosphereColor?: string;
    planetCloudTexture?: Texture;
    isFocused?: boolean;
    onRender: () => void; // Event that notifies the parent when the planet has been rendered for the first time
    onClick: (clickedId: string) => void; // Event that notifies the parent component when the planet is clicked
    goBack: () => void; // Event that notifies the parent that we should go back to the galaxy view
};

export type OK_Button = {
    
        buttonStyle: ButtonStyles;
        OnSubmit?: () => void;
        text?: React.ReactNode;
        additionalClassStyling?: string;
};

export type SpaceshipAnimationProps = {
    
    animateShip: boolean; // Whether or not the spaceship should show it's animated thrusters
    shipThrusterLevels: number; // The current level of the spaceship's thrusters (the BloomEffect amount)
    shipThrusterIntensity: number; // The current intensity of the spaceship's thrusters (the BloomEffect intensity)
    shipThrusterPosition_z: number; // The position of the actual thrusters inside of model of the spaceship (we move them forward, while animating).
    targetThrusterColor?: number; // The color of the thrusters
}

// #endregion

// #region Database Types

export enum MONGO_DB_RESPONSE_TYPES {

    INTERNAL_SERVER_ERROR = "An Internal server has been encountered",
    INVALID_USER_FORMAT_REQUEST = "Invalid user data format, found in request!",
    MISSING_REQUEST_PARAMETER = "Missing parameter in request!",
    SOCIAL_PROFILE_DATA_ERROR = "Error fetching social profile data!",
    UNEXPECTED_API_RESPONSE = "Unexpected API response!",
    UNEXPECTED_ERROR = "An unexpected error occurred!",
    USER_ALREADY_EXISTS = "User already exists",
    USER_ADDED = "User added successfully!",
    USER_ADDED_FAILED = "Failed to add user",
    USER_GET_FAILED = "Failed to fetch user!",
    USER_NOT_FOUND = "User not found!",
    USER_PARAMETER_MISSING_SUB = "Missing 'sub' parameter in request!",
}

export type ServerlessReturnType = 
{
    statusCode: number;
    headers: { [key: string]: string };
    body: string;
};

export class User {

    // Class variables
    private auth_type: SocialAuthType;
    private auth0_sub: string;
    private _username: string;
    private readonly _id: string;
    public readonly email: string;
    public readonly name: string;
    public readonly picture?: string;
    public readonly created_at?: string;  // Optional field if not always returned

    constructor(auth0_type: SocialAuthType, auth0_sub: string, username: string, email: string, name: string, picture: string, _id?: string, created_at?: string) {

        this._id = _id;
        this._username = username;
        this.auth_type = auth0_type;
        this.auth0_sub = auth0_sub;
        this.email = email;
        this.name = name;
        this.picture = picture;
        this.created_at = created_at;
    }

    // #region Getters and Setters

    getAuth0Sub(): string {
        return this.auth0_sub;
    }

    getAuthType(): SocialAuthType {
        return this.auth_type;
    }

    get username(): string { // Return the class's internal username
        return this._username; 
    }

    private set username(newName: string) {
        this._username = newName;
    }

    // #endregion!

    // Controlled modification of the user's username
    public changeUsername(newName: string) {
        this.username = newName; // call the class setter
    }

    // Static method to parse JSON into a User object
    static fromSocialAPI_JSON(json: any, provider : SocialAuthType): User {

        // Return a different parsing of the JSON object, depending on the provider
        switch(provider) {

            case SocialAuthType.GOOGLE:           
                return new User(provider, json.sub,
                                json.username, json.email,
                                json.name, json.picture);

            case SocialAuthType.TWITTER:
                return new User(provider, json.id,
                                json.username, json.email, // Twitter does not provide email
                                json.name, json.profile_image_url);

        }

    }

    // Method to translate the JSON user object from the database into a User object
    static fromDatabaseJSON(json: any): User {

        console.log("(User.fromDatabaseJSON) - Parsing user data from database into User object!");
        console.log(json);

        const auth0_provider = json.auth0_provider;
        const auth0_sub = json.auth0_sub;
        const username = json.username;
        const email = json.email;
        const name = json.name;
        const picture = json.picture;
        const created_at = json.created_at;
        const _id = json._id;

        return new User(auth0_provider, auth0_sub, username, email, name, picture, _id, created_at);
    }

    // Static method to check if a user is missing data
    static isUserMissingData(user: User): boolean {

        if(!user) {
            console.log("(User.isUserMissingData) - User object is undefined!");
            return true;
        }

        if(!user.auth_type) {
            console.log("(User.isUserMissingData) - User is missing 'auth_type' field!");
            return true;
        }

        if(!user.auth0_sub) {
            console.log("(User.isUserMissingData) - User is missing 'auth0_sub' field!");
            return true;
        }
        if(!user.name) {
            console.log("(User.isUserMissingData) - User is missing 'name' field!");
            return true;
        }
        if(!user.email) {
            console.log("(User.isUserMissingData) - User is missing 'email' field!");
            return true;
        }

        return false;
    }
}

// #endregion

// #region Auth0 Types

export enum SocialAuthType {
    
    GOOGLE = "google-oauth2",
    TWITTER = "twitter"
};

export type Auth0ExchangeCodeResponse = {
        
        access_token: string;
        id_token?: string;
        refresh_token?: string;
};

export enum Auth0ProviderResponseType {

    EXCHANGE_CODE_FAILURE = "Failed to exchange authorization code!",
    RETRIEVE_EXCHANGE_CODE_ERROR = "Error retrieving exchange code from Provider!",
    UNKNOWN_PROVIDER = "Unknown provider encountered!",
    USERDATA_FETCH_ERROR = "Error fetching user data from provider!",
    USERDATA_MISSING_TOKENS = "User data is missing access or id tokens!",
};

// #endregion