import React, {createContext, useRef} from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Routes, Route, Location } from 'react-router-dom'
import { Auth0Provider } from '@auth0/auth0-react'
import { Howler } from 'howler'

import { RouteType, SceneName } from './data types/mystarpath_types'
import SceneManager from './SceneManager'
import ErrorScene from './scenes/Error_Encountered/ErrorScene'
import './style.css'

// Disable autoUnlock to prevent audio from automatically unlocking playing on mobile devices
Howler.autoUnlock = false;

const domain = import.meta.env.VITE_AUTH0_DOMAIN;
const clientId = import.meta.env.VITE_AUTH0_CLIENT_ID;
const isInProduction = import.meta.env.MODE === 'production';
const redirect_uri_link= (isInProduction) ? import.meta.env.VITE_AUTH0_REDIRECT_PRODUCTION_URI : 
                                            import.meta.env.VITE_AUTH0_REDIRECT_DEV_URI;

console.log("Redirect URI: ", redirect_uri_link);

const root = ReactDOM.createRoot(document.querySelector('#root'));

// redirect_uri = window.location.origin is used to redirect the user back to the original page after authentication
root.render(   

    <Auth0Provider domain={domain}
                   clientId={clientId}
                   authorizationParams={{ redirect_uri: redirect_uri_link }}>

        <BrowserRouter>
            <Routes>
                <Route path={ RouteType.HOME_PAGE } 
                    element={<SceneManager /> }>

                    <Route path={ RouteType.CREATE_ACCOUNT_PATH } 
                        element={ <></> } />

                    <Route path={RouteType.LOGIN_PATH} element={<></>}>
                        <Route path={ RouteType.OAUTH_REQUEST_PATH } element={<></>} /> {/* Request route for Auth0 */}
                        <Route path={ RouteType.OAUTH_CALLBACK_PATH } element={<></>} /> {/* Callback route for Auth0 */}
                    </Route>

                    <Route path={ RouteType.ERROR_PATH } element={<></>} />  {/* Error route - programmatic errors handled in SceneManager!~ */}
                </Route>

                <Route path="*" element={<ErrorScene error={{ statusText: "404", message: "Path not found!" }}
                                                    previousScene={ SceneName.NONE } />} />

            </Routes>
        </BrowserRouter>

    </Auth0Provider>


)